<app-navbar-one></app-navbar-one>
<!-- <app-navbar-two></app-navbar-two> -->
<!-- <app-navbar-three></app-navbar-three> -->
<!-- <app-navbar-four></app-navbar-four> -->
<div class="container">
	<h3><strong>Terms and Conditions</strong></h3>
	<p><br /></p>
	<p><em>Last updated: 26 Jul 2024</em></p>
	<p><br /></p>

	<p>
		Welcome to our website. If you continue to browse and use this website,
		you are agreeing to comply with and be bound by the following terms and
		conditions of use, which together with our privacy policy govern EMMROB
		TECH & FRIENDS S.R.L.&apos;s relationship with you in relation to this
		website. If you disagree with any part of these terms and conditions,
		please do not use our website.
	</p>
	<p><br /></p>
	<p>
		The term &apos;EMMROB TECH & FRIENDS S.R.L.&apos; or &apos;us&apos; or
		&apos;we&apos;or &apos;TeamM&apos; refers to the owner of the website
		whose registered office is Jud. Bihor, Sat Pociovelişte Com. Curăţele,
		Pociovelişte, Nr.10/A. Our company registration number is J05/2230/2022.
		The term &apos;you&apos; refers to the user or viewer of our website.
	</p>
	<p><br /></p>
	<p>The use of this website is subject to the following terms of use:</p>
	<p><br /></p>
	<p>
		The content of the pages of this website is for your general information
		and use only. It is subject to change without notice.
	</p>
	<p>
		This website uses cookies to monitor browsing preferences. If you do
		allow cookies to be used, the following personal information may be
		stored by us for use by third parties.
	</p>
	<p>
		Neither we nor any third parties provide any warranty or guarantee as to
		the accuracy, timeliness, performance, completeness, or suitability of
		the information and materials found or offered on this website for any
		particular purpose. You acknowledge that such information and materials
		may contain inaccuracies or errors and we expressly exclude liability
		for any such inaccuracies or errors to the fullest extent permitted by
		law.
	</p>
	<p>
		Your use of any information or materials on this website is entirely at
		your own risk, for which we shall not be liable. It shall be your own
		responsibility to ensure that any products, services, or information
		available through this website meet your specific requirements.
	</p>
	<p>
		This website contains material which is owned by or licensed to us. This
		material includes, but is not limited to, the design, layout, look,
		appearance, and graphics. Reproduction is prohibited other than in
		accordance with the copyright notice, which forms part of these terms
		and conditions.
	</p>
	<p>
		All trademarks reproduced in this website, which are not the property
		of, or licensed to the operator, are acknowledged on the website.
	</p>
	<p>
		Unauthorized use of this website may give rise to a claim for damages
		and/or be a criminal offense.
	</p>
	<p>
		From time to time, this website may also include links to other
		websites. These links are provided for your convenience to provide
		further information. They do not signify that we endorse the website(s).
		We have no responsibility for the content of the linked website(s).
	</p>
	<p>
		Your use of this website and any dispute arising out of such use of the
		website is subject to the laws of Romania.
	</p>
	<br />
	<h4>Book a Demo Form</h4>
	<br />
	<p>
		By completing the &quot;Book a Demo&quot; form on this website, you
		agree to provide accurate and truthful information. The information
		collected through this form may be used by EMMROB TECH & FRIENDS S.R.L.
		to contact you regarding scheduling a demo, providing further
		information about our products or services, or for marketing purposes.
		<!-- We will handle your information in accordance with our Privacy Policy. -->
	</p>
</div>
