import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { WaitListService } from "../../../shared/services/wait-list.service";
import { AosAnimation, Config } from "../../../shared/config/config";
import { MixpanelService } from "../../../shared/services/mixpanel.service";

export interface IService {
  name: string;
  text: string;
  logo?: string;
}

export enum SaasSection {
  DEMO_VIDEO = "demoVideo",
  AREAS_SECTION = "areasSection",
  FEATURES_SECTION = "featuresSection",
  WAITLIST_FORM = "join",
  FAQS = "faqs",
  TOP_SECTION = "topSection",
}
@Component({
  selector: "app-saas-home",
  templateUrl: "./saas-home.component.html",
  styleUrls: ["./saas-home.component.scss"],
})
export class SaasHomeComponent implements OnInit, AfterViewInit {
  // @ViewChild('demoVideo') public demoVideo: ElementRef;
  // @ViewChild('areasSection') public areasSection: ElementRef;
  // @ViewChild('featuresSection') public featuresSection: ElementRef;
  // @ViewChild('waitListForm') public waitListForm: ElementRef;
  // @ViewChild('faqsSection') public faqsSection: ElementRef;
  // @ViewChild('topSection') public topSection: ElementRef;
  @ViewChildren(
    `${SaasSection.DEMO_VIDEO}, ${SaasSection.AREAS_SECTION}, ${SaasSection.FEATURES_SECTION}, ${SaasSection.WAITLIST_FORM}, ${SaasSection.FAQS}, ${SaasSection.TOP_SECTION}`
  )
  elms: QueryList<any>;

  oneColor = "-one-color";
  // oneColor = '';
  areas: IService[] = [
    {
      name: "Patient management",
      text: "Replacing papers and spreadsheets",
      logo: "patient-management" + this.oneColor,
    },
    {
      name: "Treatment scheduling",
      text: "Automatic generation of all schedules",
      logo: "calendar" + this.oneColor,
    },
    {
      name: "Research",
      text: "Collect data and generate reports and statistics",
      logo: "analytics" + this.oneColor,
    },
    {
      name: "Bookings",
      text: "Rooms management and online bookings",
      logo: "online-booking" + this.oneColor,
      // logo: 'online-registration.png',
    },
    {
      name: "Kitchen",
      text: "Adjust recipes for any number of guests",
      logo: "kitchen" + this.oneColor,
    },

    // {
    // 	name: 'Accounting',
    // 	text: 'Financial management',
    // 	logo: 'accounting' + this.oneColor,
    // },
  ];

  features: IService[] = [
    {
      name: "Mobile app",
      text: "Notifications and easy access for employees and guests",
      logo: "mobile-phone" + this.oneColor,
    },
    {
      name: "Automatic SMS/Email",
      text: "Easy, fast, impossible to forget",
      logo: "mobile-sms" + this.oneColor,
    },
    {
      name: "Medical data privacy",
      text: "2FA, activity logs, export & anonymize data",
      logo: "privacy" + this.oneColor,
    },
    {
      name: "Follow up",
      text: "Keep in touch with patients",
      logo: "friends" + this.oneColor,
    },
    {
      name: "Blood tests",
      text: "Import blood tests results",
      logo: "blood-tube" + this.oneColor,
    },
  ];

  aosAnimation = Config.aos.animation;
  aosDuration = Config.aos.duration; //ms
  aosShowDelay = Config.aos.showDelay;

  aosShape = {
    animation: AosAnimation.FADE_UP_RIGHT,
    duration: 800,
    delay: 600,
  };
  aosMedia = {
    animation: AosAnimation.FADE,
    duration: 1000,
    delay: 0,
  };
  detectedElms = [];

  SaasSection = SaasSection;
  isMobile: boolean = false;

  detectElms() {
    const detectedElms = [];
    this.elms.forEach((elm, index) => {
      if (isInViewport(elm.nativeElement)) {
        detectedElms.push(elm.nativeElement.id);
      }
    });

    this.detectedElms = detectedElms;
  }

  constructor(
    private renderer: Renderer2,
    private mixpanelService: MixpanelService
  ) {
    this.renderer.listen("window", "resize", this.detectElms.bind(this));
    this.renderer.listen("window", "scroll", this.detectElms.bind(this));
  }

  ngAfterViewInit() {
    setTimeout(this.detectElms.bind(this));
  }

  ngOnInit(): void {
    this.areas.push({
      name: "And more",
      text: "Send us your request for an area that you need covered",
      logo: "menu",
    });
    this.features.push({
      name: "And more",
      text: "There are a lot more helpful tools built to help you",
      logo: "menu",
    });
    this.isMobile = window.innerWidth < 800;
    this.mixpanelService.track("View Saas Page", {});
  }

  scrollTo(section: any) {
    section.scrollIntoView();
  }

  showMedia(section: SaasSection) {
    // if (this.detectedElms[1] === SaasSection.DEMO_VIDEO) return false;
    if (this.detectedElms.includes(section)) {
      if (this.detectedElms[0] === section) return true;
      if (this.detectedElms.length === 1 && this.detectedElms[0] === section)
        return true;
    }
    return false;
  }

  clickedYoutube() {
    this.mixpanelService.track("Clicked youtube video", {});
  }
}

function isInViewport(elm) {
  var elementTop = elm.offsetTop;
  var elementBottom = elementTop + elm.offsetHeight;

  // in this specific case the scroller is document.documenxtElement (<html></html> node)
  var viewportTop =
    document.documentElement.scrollTop + 0.5 * window.innerHeight;
  var viewportBottom = viewportTop + document.documentElement.clientHeight;

  return elementBottom > viewportTop && elementTop < viewportBottom;
}
