export enum AosAnimation {
	FADE_UP = 'fade-up',
	FADE = 'fade',
	FADE_DOWN = 'fade-down',
	FADE_LEFT = 'fade-left',
	FADE_RIGHT = 'fade-right',
	FADE_UP_RIGHT = 'fade-up-right',
	FADE_UP_LEFT = 'fade-up-left',
	FADE_DOWN_RIGHT = 'fade-down-right',
	FADE_DOWN_LEFT = 'fade-down-left',

	ZOOM_IN = 'zoom-in',
	ZOOM_IN_UP = 'zoom-in-up',
	ZOOM_IN_DOWN = 'zoom-in-down',
	ZOOM_IN_LEFT = 'zoom-in-left',
	ZOOM_IN_RIGHT = 'zoom-in-right',

	// ZOOM_OUT_RIGHT ...
}

export class Config {
	public static aos = {
		animation: AosAnimation.FADE_UP,
		duration: 250, //ms
		showDelay: {
			slow: 200,
			medium: 450,
			fast: 600,
			faster: 800,
		},
	};
}
