import { Component, OnInit } from '@angular/core';
import { IService } from '../saas-home/saas-home.component';
import { Config } from '../../../shared/config/config';
import { MixpanelService } from '../../../shared/services/mixpanel.service';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
	faqs: IService[] = [
		// status
		{
			name: 'What is the status?',
			text: `The app is in development and a beta version is currently being used by 
			<a href="https://centruldumbrava.ro/" target="_blank">Dumbrava,</a>
			<a href="https://saned.ro/" target="_blank">Saned,</a>
			<a href="https://herghelia.org/" target="_blank">Herghelia</a> and
			<a href="https://www.fredheim.org/en/" target="_blank">Fredheim</a>
			`,
		},
		// when
		{
			name: 'When can I use it?',
			text:
				"While the app is still in beta, we want to collaborate with a few different centers to get to know their real needs in order to build a fully adaptable and flexible product, to fit everyone's unique workflow. " +
				'Get in touch, and you will be able to use it very soon.',
		},
		// waiting list
		{
			name: "What's up with the waiting list?",
			text: "If you're on the waiting list, it means you are interested in this platform, and we will keep in touch with you. We cannot onboard multiple centers at the same time, so we will take them one by one from the waiting list.",
		},
		// pricing
		// {
		// 	name: 'What is the pricing?',
		// 	text: "Because we are still in development, we don't have a price yet. What we can tell you at the moment is that the price will not be fixed, but dynamic for each center and cheap enough so everyone will be able to afford it.",
		// },
		// languages
		{
			name: 'Is the app available in my language?',
			text: 'Currently, the app is available in English, Romanian and Norwegian , but for every center from a different country that we onboard, we will add their language.',
		},
		// roles & permissions
		{
			name: 'Does the app have different roles, so that not everybody can see the same data?',
			text: 'Yes! And the permissions for each user are configurable.',
		},
		// ICD
		// {
		// 	name: '',
		// 	text: '',
		// },
		// updates
		{
			name: 'What if we need some extra features?',
			text: 'We will implement new features based on requests from all the centers, starting with the ones with most upvotes.',
		},
	];

	aosAnimation = Config.aos.animation;
	aosDuration = Config.aos.duration; //ms
	aosShowDelay = Config.aos.showDelay;

	constructor(private mixpanelService: MixpanelService) {}

	ngOnInit(): void {}

	trackQuestion(question: string, opened) {
		if (!opened) return;
		this.mixpanelService.track('FAQ', {
			question,
		});
		this.mixpanelService.track(question, {
			question,
		});
	}
}
