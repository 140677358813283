<app-navbar-one></app-navbar-one>

<section class="about-section ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="circulate-image">
          <img src="assets/img/about.jpg" alt="image" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-content-area">
          <span>About us</span>
          <h5>Vision statement</h5>
          <p>
            Our vision is to lead people to a thriving life of physical, mental
            and spiritual health, through innovative technology and
            comprehensive resources.
          </p>
          <h5>Mission statement</h5>
          <p>
            Our mission is to guide individuals on a sustainable path to
            holistic health by fostering lifestyle changes and building
            supportive communities. We connect people with the right people,
            equip them with essential tools, and provide ongoing support to aid
            them in their journey.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="what-we-do-section pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="we-do-content-area">
          <span>What We do</span>
          <h3>We develop digital strategies, products, and services</h3>
          <p>
            There are many tools out there, but we focus on filling in the gaps.
            We want to provide tools that are specific enough to achieve maximum
            efficiency, yet broad enough to serve a large number of customers.
            At TeamM, we focus on empowering health and lifestyle centers to
            provide the best possible care to their guests.
          </p>
          <p>
            We believe in lifestyle medicine - adressing the root of the issue.
            That's why we want to support health and lifestyle centers and power
            them up by offering them industry-standard technology. We
            collaborate with health and lifestyle centres by creating and
            tailoring technology to suit their distinctive needs in order to
            empower them to deliver exceptional services to their guests.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="we-do-image">
          <img src="assets/img/vector.jpg" alt="image" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="saying-section ptb-100">
  <div class="container">
    <div class="saying-title">
      <h3>What clients are saying about us</h3>
      <p>
        Our customers inspire us to keep pushing forward. We deeply appreciate
        the feedback we receive and are constantly striving to enhance our
        platform. Here’s what some of our clients have shared about their
        experience with us.
      </p>
    </div>

    <div class="saying-slides owl-carousel owl-theme">
      <div class="saying-single-item">
        <div class="saying-content-text">
          <h3>
            Personally, I am very satisfied.

            <!-- Personal, sunt foarte mulțumită -->
          </h3>
          <p>
            <!-- TeamM este instrumentul care eficientizează considerabil activitatea
            medicală a centrului nostru de sănătate. Personal, sunt foarte
            mulțumită că TeamM adună informațiile importante de pe toată
            perioada internării și realizează scrisoarea medicală fără efort.
            TeamM facilitează mult actul medical prin funcționalitățile care fac
            ca să fie efortul minim și rezultatul optim. -->
            TeamM is the tool that significantly streamlines the medical
            activity of our health center. Personally, I’m very satisfied that
            TeamM gathers all the important information throughout the patient’s
            stay and creates the medical discharge letter effortlessly. TeamM
            greatly facilitates medical work with its functionalities, ensuring
            minimal effort and optimal results.
          </p>
        </div>
        <div class="saying-info">
          <div class="say-image">
            <img src="assets/img/say-image/lore-c.jpg" alt="image" />
          </div>
          <h4>Loredana Catrinescu, PhD</h4>
          <span>Dumbrava Health Center</span>
        </div>
      </div>

      <div class="saying-single-item">
        <div class="saying-content-text">
          <h3>
            <!-- Pe mine TeamM mă ajută foarte mult -->
            TeamM helps me a lot.
          </h3>
          <p>
            <!-- Practic, nici nu este nevoie să ai fișa pacientului la tine, că ai
            toate detaliile legate de proceduri și tratamente la un singur
            click, cât și recomandările pe care medicii le scriu, așa încât noi
            să putem, în scurt timp, să le vizualizăm și implementăm în
            programul pacientului. Eu personal sunt mulțumitoare pentru cei care
            au făcut posibilă realizarea acestei platforme și care lucrează
            mereu pentru ajustarea ei la nevoile noastre mereu în schimbare. -->
            Practically, there’s no need to have the patient’s file with you
            because all the details about procedures and treatments are just one
            click away, as well as the recommendations that doctors write, so we
            can quickly view and implement them in the patient’s program.
            Personally, I’m grateful to those who made the creation of this
            platform possible and who continuously work to adjust it to our
            ever-changing needs.
          </p>
        </div>
        <div class="saying-info">
          <div class="say-image">
            <img src="assets/img/say-image/adela-m.png" alt="image" />
          </div>
          <h4>Adela Moroșan, Head Nurse</h4>
          <span>Dumbrava Health Center</span>
        </div>
      </div>

      <div class="saying-single-item">
        <div class="saying-content-text">
          <h3>We really like it!</h3>
          <p>
            We really like what you have done here. It's all so flexible and we
            are impressed with the speed of the development process!
          </p>
        </div>
        <div class="saying-info">
          <div class="say-image">
            <img src="assets/img/say-image/fr.webp" alt="image" />
          </div>
          <h4>Fredrik Lillback, CEO</h4>
          <span>Fredheim Lifestyle Center</span>
        </div>
      </div>

      <div class="saying-single-item">
        <div class="saying-content-text">
          <h3>
            <!-- Îmi place mult să folosesc acest program -->

            I really enjoy using this program.
          </h3>
          <p>
            Since we started using it, the work we do has become much easier.
            All the information we need is in one place; from the moment the
            patient registers for a treatment series, all their data is recorded
            and then centralized. There’s no more need to search through papers
            or different records, saving a lot of time. I can’t wait for new
            features to be ready so we can be even more efficient and practical!
            <!-- De când îl avem, s-a ușurat mult munca pe care o facem. Toate
            informațiile de care avem nevoie se află la un loc, din momentul în
            care pacientul se înscrie la o serie de tratament, toate datele lui
            sunt înregistrate și apoi centralizate. Nu mai este necesar să
            căutăm prin foi, diferite date și se economisește mult timp. Abia
            aștept să fie gata și alte funcții noi, pentru a fi și mai eficienți
            și practici! -->
          </p>
        </div>
        <div class="saying-info">
          <div class="say-image">
            <img src="assets/img/say-image/ioana-s.png" alt="image" />
          </div>
          <h4>Ioana Senocico, MD</h4>
          <span>Dumbrava Health Center</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="team-section inner-team ptb-100">
  <div class="container">
    <div class="team-title">
      <span>Behind the scenes</span>
      <h3>Our Team Members</h3>
      <p>
        Each member of our team brings a unique blend of expertise, creativity,
        and unwavering dedication to our mission. Together, we are driving
        forward the vision towards a future where wellness technology
        revolutionizes lifestyles and promotes holistic well-being.
      </p>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-member">
          <div class="member-image">
            <img src="assets/img/team/emm-bolohan.png" alt="image" />
          </div>

          <div class="member-content">
            <h3><a routerLink="/">Emm Bolohan</a></h3>
            <span>CEO</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-member">
          <div class="member-image">
            <img src="assets/img/team/rob-bolohan.png" alt="image" />
          </div>

          <div class="member-content">
            <h3><a routerLink="/">Rob Bolohan</a></h3>
            <span>Developer</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-member">
          <div class="member-image">
            <img src="assets/img/team/vlad-p.png" alt="image" />
          </div>

          <div class="member-content">
            <h3><a routerLink="/">Vlad Pătrașcu</a></h3>
            <span>Project Manager</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-member">
          <div class="member-image">
            <img src="assets/img/team/cornel-a.jpeg" alt="image" />
          </div>

          <div class="member-content">
            <h3><a routerLink="/">Cornel Anghel</a></h3>
            <span>Tester</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-member">
          <div class="member-image">
            <img src="assets/img/team/rob-bertholf.jpeg" alt="image" />
          </div>

          <div class="member-content">
            <h3><a routerLink="/">Rob Bertholf</a></h3>
            <span>Advisor & Architect</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-member">
          <div class="member-image">
            <img src="assets/img/team/magda-b.jpg" alt="image" />
          </div>

          <div class="member-content">
            <h3><a routerLink="/">Magda Bertholf</a></h3>
            <span>Consultant</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-team-member">
          <div class="member-image">
            <img src="assets/img/team/viriato-f.png" alt="image" />
          </div>

          <div class="member-content">
            <h3><a routerLink="/">Dr. Viriato Ferreira</a></h3>
            <span>Advisor</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="fun-facts-area pt-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-6">
        <div class="single-fun-fact">
          <h3>
            <span class="odometer" data-count="100">00</span>
            <span class="sign-icon">%</span>
          </h3>
          <p>Completed projects</p>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="single-fun-fact">
          <h3>
            <span class="odometer" data-count="48">00</span>
            <span class="sign-icon">+</span>
          </h3>
          <p>Customer satisfaction</p>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="single-fun-fact">
          <h3>
            <span class="odometer" data-count="24">00</span>
            <span class="sign-icon">h</span>
          </h3>
          <p>Support Team</p>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="single-fun-fact">
          <h3>
            <span class="odometer" data-count="6">00</span>
            <span class="sign-icon"></span>
          </h3>
          <p>Sales in Count</p>
        </div>
      </div>
    </div>
  </div>
</section> -->

<div class="partner-section ptb-100">
  <div class="container">
    <div class="partner-slider owl-carousel owl-theme">
      <div class="partner-item">
        <a href="#" target="_blank" class="d-block">
          <img
            src="assets/img/partnar/advent-ventures.png"
            alt="advent ventures"
          />
        </a>
      </div>

      <div class="partner-item">
        <a href="#" target="_blank" class="d-block">
          <img src="assets/img/partnar/fredheim_logo.webp" alt="fredheim" />
        </a>
      </div>

      <div class="partner-item">
        <a href="#" target="_blank" class="d-block">
          <img src="assets/img/partnar/logo-ivesti-wide.png" alt="saned" />
        </a>
      </div>

      <div class="partner-item">
        <a href="#" target="_blank" class="d-block">
          <img src="assets/img/partnar/logo-herghelia.png" alt="herghelia" />
        </a>
      </div>

      <div class="partner-item">
        <a href="#" target="_blank" class="d-block">
          <img src="assets/img/partnar/logo-dumbrava.png" alt="dumbrava" />
        </a>
      </div>
    </div>
  </div>
</div>

<!-- <section class="update-section inner-update-section ptb-100">
	<div class="container">
		<div class="update-title">
			<h3>Get Every Single Update</h3>
		</div>
		<form class="update-form">
			<input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" />
			<button type="submit">Subscribe Newsletter</button>
		</form>
	</div>

	<div class="shape">
		<img src="assets/img/shape/shape19.png" alt="Images" />
	</div>
	<div class="shape-2">
		<img src="assets/img/shape/shape20.png" alt="Images" />
	</div>
</section> -->
