import { Component, OnInit } from "@angular/core";
import { MixpanelService } from "../../../shared/services/mixpanel.service";

export enum PricingPlan {
  EXPERIMENTAL = "Experimental",
  BASIC = "Basic",
  STANDARD = "Standard",
  ADVANCED = "Advanced",
}

export interface PlanDetails {
  title: string;
  price: number;
  features: string[];
  plan: PricingPlan;
}

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent implements OnInit {
  // todo study  https://www.rxnt.com/pricing/

  viewMode: "monthly" | "yearly" = "monthly";
  yearlyDiscount = 10;

  plans: PlanDetails[] = [
    {
      title: "Front Desk",
      price: 0,
      features: [
        "Bookings",
        "Sessions",
        "Hotel rooms",
        "Message templates",
        "Export to DOCX, CSV & PDF",
        "",
        "5 custom fields",
        "1 booking form",
        "Email support",
      ],
      plan: PricingPlan.EXPERIMENTAL,
    },

    {
      title: "Front Desk + Doctor's office",
      price: 199,
      features: [
        "Everything in Front Desk",
        "Treatment plans",
        "Facility rooms",
        "Recommendations",
        "Ultrasound form",
        "Document templates",
        "Role-based acces control",
        "",
        "20 custom fields",
        "3 booking forms + 3 medical forms",
        "Email & in-app support",
      ],

      plan: PricingPlan.BASIC,
    },
    {
      title: "Front Desk + Doctor's office + Laboratory",
      price: 399,
      features: [
        "Everything in Front Desk + Doctor's office",
        "Patient intakes",
        "Statistics",
        "Automatic scheduling",
        "Blood tests results import",
        "",
        "50 custom fields",
        "5 booking forms + 5 medical forms",
        "Email & in-app support",
      ],
      plan: PricingPlan.STANDARD,
    },
    {
      title: "Entire building",
      price: 999,
      features: [
        "Everything in Front Desk + Doctor's office + Laboratory",
        "Orders",
        "Pricing",
        "Programs",
        "Packages",
        "Discounts & Vouchers",
        "Kitchen management (coming soon)",
        "Task management (coming soon)",
        "Research+ (coming soon)",
        "",
        "Public booking forms",
        "Unlimited custom fields",
        "Unlimited booking and medical forms",
        "Email, in-app, WhatsApp & phone support",
      ],
      plan: PricingPlan.ADVANCED,
    },
  ];

  // todo
  // add-ons
  // table with detailed features https://codepen.io/bick/details/pyZKRQ
  // onboarding services

  constructor(private mixpanelService: MixpanelService) {}

  ngOnInit(): void {
    this.mixpanelService.track("View Pricing Page", {});
  }
}
