<app-navbar-one></app-navbar-one>

<div class="seo-agency-home-area">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="hero-content">
							<h1>
								SEO is Just rocket science by those who already
								know it
							</h1>
							<p>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolet, consectetur
								adipiscing seore suspendisse ultrices gravida.
							</p>

							<div class="seo-btn">
								<a routerLink="/" class="seo-btn-one"
									>Get A Quote</a
								>
								<a
									href="https://www.youtube.com/watch?v=bk7McNUjWgw"
									class="video-btn popup-youtube"
									><span><i class="fa fa-play"></i></span>
									Play Video</a
								>
							</div>
						</div>
					</div>

					<div class="col-lg-12">
						<div class="seo-agency-image">
							<img
								src="assets/img/seo/seo-agency-image.png"
								alt="image"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="seo-shape">
		<img src="assets/img/seo/shape.png" alt="image" />
	</div>
	<div class="shape-img1">
		<img src="assets/img/shape/shape1.svg" alt="image" />
	</div>
	<div class="shape-img2">
		<img src="assets/img/shape/shape2.svg" alt="image" />
	</div>
	<div class="shape-img3">
		<img src="assets/img/shape/shape3.png" alt="image" />
	</div>
	<div class="shape-img4">
		<img src="assets/img/shape/shape4.png" alt="image" />
	</div>
	<div class="shape-img5">
		<img src="assets/img/shape/shape6.png" alt="image" />
	</div>
	<div class="nav-shape">
		<img src="assets/img/shape/shape18.png" alt="image" />
	</div>
</div>

<section class="visitor-section pb-70">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="visitor-title">
					<h2>Growth your SEO traffic visitor score</h2>
				</div>
			</div>

			<div class="col-lg-6 col-md-12">
				<div class="visitor-text">
					<p>
						There are many variations of passages of Lorem Ipsum
						available, but the majority have suffered alteration in
						some form, by injected humor, or randomized words which
						don't look even slightly believable.
					</p>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="visitor-content-area">
					<div class="icon">
						<i class="flaticon-data"></i>
					</div>
					<h3>Data Analysis</h3>
					<p>
						Lorem ipsum dolor sit amet consecte adipiscing elit sed
						do eiusincidunt.
					</p>

					<div class="visitor-shape">
						<img src="assets/img/seo/shape-2.png" alt="image" />
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="visitor-content-area bg-00a86f">
					<div class="icon">
						<i class="flaticon-goal"></i>
					</div>
					<h3>Web Analysis</h3>
					<p>
						Lorem ipsum dolor sit amet consecte adipiscing elit sed
						do eiusincidunt.
					</p>

					<div class="visitor-shape">
						<img src="assets/img/seo/shape-2.png" alt="image" />
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="visitor-content-area bg-302353">
					<div class="icon">
						<i class="flaticon-modules"></i>
					</div>
					<h3>Media Promotion</h3>
					<p>
						Lorem ipsum dolor sit amet consecte adipiscing elit sed
						do eiusincidunt.
					</p>

					<div class="visitor-shape">
						<img src="assets/img/seo/shape-2.png" alt="image" />
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="visitor-content-area bg-c53a17">
					<div class="icon">
						<i class="flaticon-cyber-security"></i>
					</div>
					<h3>Tools Management</h3>
					<p>
						Lorem ipsum dolor sit amet consecte adipiscing elit sed
						do eiusincidunt.
					</p>

					<div class="visitor-shape">
						<img src="assets/img/seo/shape-2.png" alt="image" />
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="specialty-section pb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6">
				<div class="specialty">
					<img src="assets/img/specialty.jpg" alt="image" />
				</div>
			</div>

			<div class="col-lg-6">
				<div class="specialty-content-area">
					<span>Our Specialty</span>
					<h3>Growth your SEO traffic visitor organically</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit, sed do eiusmod tempor incididunt ut labore et
						dolore magna aliqua. Ut enim ad minim venia.
					</p>

					<ul class="specialty-list">
						<li>
							<i class="fa fa-arrow-right"></i> Marketing Goal
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Speed Optimization
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Analytical Goal
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Customer
							Experience
						</li>
					</ul>

					<div class="specialty-btn">
						<a routerLink="/" class="specialty-btn-one"
							>Learn More</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="department-section pb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="department-area-content">
					<span>Our Department</span>
					<h3>Our Structure & Marketing Department Support</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit, sed do eiusmod tempor incididunt ut labore et
						dolore magna aliqua. Ut enim ad minim venia.
					</p>

					<ul class="department-list">
						<li>
							<i class="fa fa-arrow-right"></i> Web site Analysis
							& Management
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Website Support &
							Assistance
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Web site Analysis
							& Live support
						</li>
					</ul>

					<div class="department-btn">
						<a routerLink="/contact" class="department-btn-one"
							>Contract Support</a
						>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-12">
				<div class="department">
					<img src="assets/img/department.jpg" alt="image" />
				</div>
			</div>
		</div>
	</div>
</section>

<section class="success-section ptb-100">
	<div class="container">
		<div class="success-title">
			<h3>Our Success Rate</h3>
		</div>

		<div class="row">
			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="success-area-content">
					<i class="flaticon-industry"></i>
					<h3>Establishment</h3>
					<span>January 2002</span>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="success-area-content">
					<i class="flaticon-group"></i>
					<h3>Happy Clients</h3>
					<span>130+ Happy</span>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="success-area-content">
					<i class="flaticon-promotion"></i>
					<h3>Successful Work</h3>
					<span>160+ Successful project</span>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="success-area-content">
					<i class="flaticon-coffee-cup"></i>
					<h3>Cup Of Tea</h3>
					<span>50K Cup of Tea</span>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="partnar-three-section pt-100">
	<div class="container">
		<div class="partner-slider owl-carousel owl-theme">
			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img1.png"
						alt="partner"
					/>
				</a>
			</div>

			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img2.png"
						alt="partner"
					/>
				</a>
			</div>

			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img3.png"
						alt="partner"
					/>
				</a>
			</div>

			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img4.png"
						alt="partner"
					/>
				</a>
			</div>

			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img5.png"
						alt="partner"
					/>
				</a>
			</div>
		</div>
	</div>
</div>

<section class="offer-section">
	<div class="container">
		<div class="offer-title">
			<span>Services We offer</span>
			<h3>Our Products and Service</h3>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
				eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
				enim ad minim venia.
			</p>
		</div>

		<div class="row">
			<div class="col-lg-6 col-md-6">
				<div class="products-area-content">
					<div class="products-image">
						<img
							src="assets/img/products/products-img1.png"
							alt="image"
						/>
					</div>
					<h3>Context Ads Service</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing el
						sed do eiusmod tempor incididunt ut labore et dolore
						magna aliqua. Ut enim ad minim venia.
					</p>
					<a routerLink="/services-details" class="products-btn-one"
						>Learn More</a
					>
					<div class="products-text">
						<span>01</span>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-6">
				<div class="products-area-content">
					<div class="products-image">
						<img
							src="assets/img/products/products-img2.png"
							alt="image"
						/>
					</div>
					<h3>Target Ads Service</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing el
						sed do eiusmod tempor incididunt ut labore et dolore
						magna aliqua. Ut enim ad minim venia.
					</p>
					<a routerLink="/services-details" class="products-btn-one"
						>Learn More</a
					>
					<div class="products-text">
						<span>02</span>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-6">
				<div class="products-area-content">
					<div class="products-image">
						<img
							src="assets/img/products/products-img3.png"
							alt="image"
						/>
					</div>
					<h3>Web Analytics Issues</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing el
						sed do eiusmod tempor incididunt ut labore et dolore
						magna aliqua. Ut enim ad minim venia.
					</p>
					<a routerLink="/services-details" class="products-btn-one"
						>Learn More</a
					>
					<div class="products-text">
						<span>03</span>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-6">
				<div class="products-area-content">
					<div class="products-image">
						<img
							src="assets/img/products/products-img4.png"
							alt="image"
						/>
					</div>
					<h3>Link Building Issues</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing el
						sed do eiusmod tempor incididunt ut labore et dolore
						magna aliqua. Ut enim ad minim venia.
					</p>
					<a routerLink="/services-details" class="products-btn-one"
						>Learn More</a
					>
					<div class="products-text">
						<span>04</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="increase-section">
	<div class="container">
		<div class="increase-title">
			<span>What’s Increase</span>
			<h3>Experts Marketer in online marketing Solution</h3>
			<div class="increase-btn">
				<a routerLink="/" class="increase-btn-one">Know More</a>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-6 col-md-6">
				<div class="increase-item">
					<div class="increase-area-content">
						<img src="assets/img/icon/icon5.png" alt="image" />
						<h3>Grow Research & Marketing Strategy Solution</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmosum dolorum dolor.
						</p>
					</div>

					<div class="increase-shape">
						<img src="assets/img/icon/shape.png" alt="image" />
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-6">
				<div class="increase-item">
					<div class="increase-area-content">
						<img src="assets/img/icon/icon6.png" alt="image" />
						<h3>Increase The Revenue Strategy Solution</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmosum dolorum dolor.
						</p>
					</div>

					<div class="increase-shape">
						<img src="assets/img/icon/shape.png" alt="image" />
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-6">
				<div class="increase-item">
					<div class="increase-area-content">
						<img src="assets/img/icon/icon7.png" alt="image" />
						<h3>Grow Research & Marketing Strategy Solution</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmosum dolorum dolor.
						</p>
					</div>

					<div class="increase-shape">
						<img src="assets/img/icon/shape.png" alt="image" />
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-6">
				<div class="increase-item">
					<div class="increase-area-content">
						<img src="assets/img/icon/icon8.png" alt="image" />
						<h3>Grow Research & Marketing Strategy Solution</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmosum dolorum dolor.
						</p>
					</div>

					<div class="increase-shape">
						<img src="assets/img/icon/shape.png" alt="image" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="shape">
		<img src="assets/img/shape/shape19.png" alt="Images" />
	</div>
</section>

<section class="pricing-section ptb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="pricing-area-content seo-price-title">
					<span>pricing</span>
					<h3>Management is easy when getting support properly</h3>
					<p>
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Repellat, temporibus autem! Provident, soluta quod
						atque libero magnam accusamus quasi inventore enim
						accusamus quasi laboriosam voluptates laudantium
						veritatis aliquid labore error molestiae nulla?
					</p>
					<p>
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Pariatur, impedit debitis! Ipsum ratione dolore
						sit numquam dicta ex officiis accusantium accusamus iure
						nihil? Accusamus aspernatur harum provident.
					</p>
				</div>

				<div class="pricing-btn-wrap">
					<div class="price-btn">
						<a routerLink="/pricing" class="price-btn-one"
							>Pricing Plan <i class="fa fa-arrow-right"></i
						></a>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6">
				<div class="single-pricing-box seo-price price-z">
					<div class="pricing-header">
						<h3>Bussines Account</h3>
					</div>

					<div class="price">$56/ <span>Monthly</span></div>

					<ul class="pricing-features">
						<li>
							<i class="fa fa-arrow-right"></i> Drug & Drop
							builder
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 1009s account
							ready
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Unlimited Email
						</li>
						<li><i class="fa fa-arrow-right"></i> Blog tools</li>
						<li>
							<i class="fa fa-arrow-right"></i> Ecommerce store
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 40+ Webmaster
							tools
						</li>
					</ul>

					<div class="buy-btn">
						<a routerLink="/" class="buy-btn-one"
							>Create a Free Account</a
						>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6">
				<div class="single-pricing-box table-price seo-price">
					<div class="pricing-header">
						<h3>Basic Account</h3>
					</div>

					<div class="price">$70/ <span>Monthly</span></div>

					<ul class="pricing-features">
						<li>
							<i class="fa fa-arrow-right"></i> Drug & Drop
							builder
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 1009s account
							ready
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Unlimited Email
						</li>
						<li><i class="fa fa-arrow-right"></i> Blog tools</li>
						<li>
							<i class="fa fa-arrow-right"></i> Ecommerce store
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 40+ Webmaster
							tools
						</li>
					</ul>

					<div class="buy-btn">
						<a routerLink="/" class="buy-btn-one"
							>Start a Free trial</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="team-section pt-100 pb-70">
	<div class="container">
		<div class="team-title">
			<span>Our Team Members</span>
			<h3>Executive Team Support</h3>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
				eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
				enim ad minim venia.
			</p>
		</div>

		<div class="row align-items-center">
			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team1.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">White Arlington</a></h3>
						<span>Manager Logistic</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team2.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">Agaton Ronald</a></h3>
						<span>Scientist Ecology</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team3.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">Alex Wood</a></h3>
						<span>Scientist Economic</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team4.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">Maxwell White</a></h3>
						<span>Owner & CEO of Economic</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="shape"><img src="assets/img/shape/shape21.png" alt="" /></div>
</section>

<section class="request-section ptb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="request-area">
					<h3>Submit a Request</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit
						sed do eiusmod tempor incididunt.
					</p>

					<div class="request-form">
						<form>
							<div class="form-group">
								<input
									type="text"
									name="name"
									class="form-control"
									placeholder="What's Your Name"
								/>
							</div>

							<div class="form-group">
								<input
									type="text"
									name="phone_number"
									class="form-control"
									placeholder="Your Phone Number"
								/>
							</div>

							<div class="form-group">
								<input
									type="text"
									name="subject"
									class="form-control"
									placeholder="Time For call"
								/>
							</div>

							<div class="form-group">
								<textarea
									name="message"
									class="form-control"
									cols="30"
									rows="6"
									placeholder="Your Consultancy Expert"
								></textarea>
							</div>

							<button type="submit" class="btn btn-primary">
								Call For Consultent
							</button>
						</form>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-12">
				<div class="request">
					<img src="assets/img/request.jpg" alt="image" />
				</div>
			</div>
		</div>
	</div>
</section>

<section class="seo-blog-section pb-70">
	<div class="container">
		<div class="blog-title-two">
			<span>Latest News Update</span>
			<h3>Latest Updates You will find</h3>
		</div>

		<div class="row">
			<div class="col-lg-4 col-md-6">
				<div class="single-blog">
					<div class="blog-image">
						<a routerLink="/blog-details" class="d-block"
							><img
								src="assets/img/seo-blog/blog-img1.png"
								alt="image"
						/></a>

						<div class="blog-bate">
							<span>01</span>
							<span>Jan</span>
						</div>
					</div>

					<div class="single-blog-item">
						<div class="blog-content">
							<a class="admin" routerLink="/"
								><i class="flaticon-user"></i> JKapler Done</a
							>
							<a
								class="blog-link d-block"
								routerLink="/blog-details"
								><h3>
									Great team management work makes a
									beautyfull work
								</h3></a
							>
							<p>
								Lorem ipsum dolor sit amconsectetur adipiscing
								elit, sed do eiusmodor.
							</p>
							<a class="read-more" routerLink="/blog-details"
								>Read More <i class="fa fa-arrow-right"></i
							></a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-6">
				<div class="single-blog">
					<div class="blog-image">
						<a routerLink="/blog-details" class="d-block"
							><img
								src="assets/img/seo-blog/blog-img2.png"
								alt="image"
						/></a>

						<div class="blog-bate">
							<span>01</span>
							<span>Jan</span>
						</div>
					</div>

					<div class="single-blog-item">
						<div class="blog-content">
							<a class="admin" routerLink="/"
								><i class="flaticon-user"></i> JKapler Done</a
							>
							<a
								class="blog-link d-block"
								routerLink="/blog-details"
								><h3>
									Amazing Supporting is key to touch main for
									thing
								</h3></a
							>
							<p>
								Lorem ipsum dolor sit amconsectetur adipiscing
								elit, sed do eiusmodor.
							</p>
							<a class="read-more" routerLink="/blog-details"
								>Read More <i class="fa fa-arrow-right"></i
							></a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
				<div class="single-blog">
					<div class="blog-image">
						<a routerLink="/blog-details" class="d-block"
							><img
								src="assets/img/seo-blog/blog-img2.png"
								alt="image"
						/></a>

						<div class="blog-bate">
							<span>01</span>
							<span>Jan</span>
						</div>
					</div>

					<div class="single-blog-item">
						<div class="blog-content">
							<a class="admin" routerLink="/"
								><i class="flaticon-user"></i> JKapler Done</a
							>
							<a
								class="blog-link d-block"
								routerLink="/blog-details"
								><h3>
									New team here something being remarkable
								</h3></a
							>
							<p>
								Lorem ipsum dolor sit amconsectetur adipiscing
								elit, sed do eiusmodor.
							</p>
							<a class="read-more" routerLink="/blog-details"
								>Read More <i class="fa fa-arrow-right"></i
							></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="update-section ptb-100">
	<div class="container">
		<div class="update-title">
			<h3>Get Every Single Update</h3>
		</div>

		<form class="update-form">
			<input
				type="email"
				class="input-newsletter"
				placeholder="Enter your email"
				name="EMAIL"
				required
			/>
			<button type="submit">Subscribe Newsletter</button>
		</form>
	</div>

	<div class="shape">
		<img src="assets/img/shape/shape19.png" alt="Images" />
	</div>
	<div class="shape-2">
		<img src="assets/img/shape/shape20.png" alt="Images" />
	</div>
</section>
