import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Directive()
export class AbstractNgModelComponent<T = number>
	implements ControlValueAccessor
{
	@Input()
	cid: string = uuid();

	@Input()
	disabled: boolean;

	@Input()
	set value(value: T) {
		this._value = value;
		this.notifyValueChange();
	}

	componentInit = false;

	get value(): T {
		return this._value;
	}

	onChange: (value: T) => {};
	onTouched: () => {};

	protected _value: T;
	// protected cdRef: ChangeDetectorRef;

	constructor() {
		// this.cdRef = AppInjector.get(ChangeDetectorRef);
	}

	notifyValueChange(): void {
		if (this.onChange) {
			this.onChange(this.value);
		}
	}

	writeValue(value: T): void {
		this._value = value;
		this.valueChanged();
		// setTimeout(() => this.cdRef.detectChanges(), 0);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
		this.componentInit = true;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	valueChanged() {} // custom implementation
}

@Directive()
export class AbstractInputComponent<
	T = string
> extends AbstractNgModelComponent<T> {
	@Input()
	readonly: any = false;

	@Input()
	required: any = false;

	@Input()
	disabled: any = false;

	@Input()
	type: 'text' | 'number' | 'email' | 'password' = 'text';

	@Input()
	placeholder: string = '';

	@Input()
	autofocus: boolean = false;

	@Input()
	autosize: boolean = false;

	@Input()
	clearable: boolean = true;

	@Input()
	searchable: boolean = true;

	@Input() info: string;
	@Input() matHint: string;
	@Input() prefix: string;

	@Input() suffix: string;

	@Input()
	minNumber: number;
	@Input()
	maxNumber: number;

	@Input()
	maxSelectedItems: number;

	@Input()
	minLength: number;

	@Input()
	maxLength: number;

	@Input() minDate: Date;

	@Input() maxDate: Date;

	@Input() rows: number;

	@Input() maxRows: number;

	@Input() step: string;

	@Input()
	multiple: boolean = false;

	@Input() label: string;

	@Input() validationMessage: string;

	@Input() autocomplete: string;

	@Input() matTooltip: string;
	@Input() matAppearance: MatFormFieldAppearance;

	@Input() title: string;
	@Input() groupBy: string; //used for select

	@Input() closeOnSelect: boolean;
	@Input() includeMargin: boolean = true; // used for mat form group. In some cases (grid form) we don't want to include margin between form fields

	// forn ngx-mask
	@Input() specialCharacters: string[]; // ex:  [ '/' ]
	@Input() dropSpecialCharacters: boolean;
	@Input() mask: string; // ex: d0/M0/0000

	// for rooms

	@Input() roomsStatus = false;

	@Input() compareWith: (a, b) => boolean;

	@Input()
	name: string = '';

	@Input() suffixOutsideInput = false;

	@Output()
	onBlur = new EventEmitter<void>();

	@Output()
	onFocus = new EventEmitter<void>();

	get inputReadonly(): boolean {
		return this.readonly || typeof this.readonly !== 'boolean';
	}

	get inputDisabled(): boolean {
		return this.disabled || typeof this.disabled !== 'boolean';
	}

	get inputRequired(): boolean {
		return this.required || typeof this.required !== 'boolean';
	}
}
