<app-navbar-one></app-navbar-one>

<div class="page-title-area">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="page-title-content">
					<h2>Projects</h2>

					<ul>
						<li><a routerLink="/">Home</a></li>
						<li>Projects</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<section class="project-section ptb-100">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="single-project-item">
					<img
						src="assets/img/project/project-img4.jpg"
						alt="image"
					/>

					<div class="project-content">
						<h3>
							<a href="single-projects.html">SEO Consultancy</a>
						</h3>
						<p>20, Deceember 2019</p>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="single-project-item">
					<img
						src="assets/img/project/project-img5.jpg"
						alt="image"
					/>

					<div class="project-content">
						<h3>
							<a href="single-projects.html"
								>Competitor Analysis</a
							>
						</h3>
						<p>20, Deceember 2019</p>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="single-project-item">
					<img
						src="assets/img/project/project-img6.jpg"
						alt="image"
					/>

					<div class="project-content">
						<h3>
							<a href="single-projects.html"
								>Social Media Marketing</a
							>
						</h3>
						<p>20, Deceember 2019</p>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="single-project-item">
					<img
						src="assets/img/project/project-img7.jpg"
						alt="image"
					/>

					<div class="project-content">
						<h3>
							<a href="single-projects.html">Strategic Planing</a>
						</h3>
						<p>20, Deceember 2019</p>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="single-project-item">
					<img
						src="assets/img/project/project-img8.jpg"
						alt="image"
					/>

					<div class="project-content">
						<h3>
							<a href="single-projects.html"
								>Design & Development</a
							>
						</h3>
						<p>20, Deceember 2019</p>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="single-project-item">
					<img
						src="assets/img/project/project-img9.jpg"
						alt="image"
					/>

					<div class="project-content">
						<h3>
							<a href="single-projects.html"
								>Technological Products</a
							>
						</h3>
						<p>20, Deceember 2019</p>
					</div>
				</div>
			</div>

			<div class="col-lg-12 col-md-12">
				<div class="pagination-area">
					<a routerLink="/" class="prev page-numbers"
						><i class="fas fa-angle-double-left"></i
					></a>
					<a routerLink="/" class="page-numbers">1</a>
					<span class="page-numbers current" aria-current="page"
						>2</span
					>
					<a routerLink="/" class="page-numbers">3</a>
					<a routerLink="/" class="page-numbers">4</a>
					<a routerLink="/" class="next page-numbers"
						><i class="fas fa-angle-double-right"></i
					></a>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="partner-section pb-100">
	<div class="container">
		<div class="partner-slider owl-carousel owl-theme">
			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img1.png"
						alt="partner"
					/>
				</a>
			</div>

			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img2.png"
						alt="partner"
					/>
				</a>
			</div>

			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img3.png"
						alt="partner"
					/>
				</a>
			</div>

			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img4.png"
						alt="partner"
					/>
				</a>
			</div>

			<div class="partner-item" target="_blank" class="d-block">
				<a href="#">
					<img
						src="assets/img/partnar/partner-img5.png"
						alt="partner"
					/>
				</a>
			</div>
		</div>
	</div>
</div>
