import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './web/layouts/preloader/preloader.component';
import { FooterComponent } from './web/layouts/footer/footer.component';
import { SaasHomeComponent } from './web/pages/saas-home/saas-home.component';
import { NavbarOneComponent } from './web/layouts/navbar-one/navbar-one.component';
import { MarketingHomeComponent } from './web/pages/marketing-home/marketing-home.component';
import { StartupHomeComponent } from './web/pages/startup-home/startup-home.component';
import { SeoAgencyHomeComponent } from './web/pages/seo-agency-home/seo-agency-home.component';
import { NavbarTwoComponent } from './web/layouts/navbar-two/navbar-two.component';
import { NavbarThreeComponent } from './web/layouts/navbar-three/navbar-three.component';
import { NavbarFourComponent } from './web/layouts/navbar-four/navbar-four.component';
import { AboutComponent } from './web/pages/about/about.component';
import { ServicesComponent } from './web/pages/services/services.component';
import { ServicesDetailsComponent } from './web/pages/services-details/services-details.component';
import { ProjectsComponent } from './web/pages/projects/projects.component';
import { ProjectsDetailsComponent } from './web/pages/projects-details/projects-details.component';
import { TeamComponent } from './web/pages/team/team.component';
import { FaqComponent } from './web/pages/faq/faq.component';
import { PricingComponent } from './web/pages/pricing/pricing.component';
import { LoginComponent } from './web/pages/login/login.component';
import { SignupComponent } from './web/pages/signup/signup.component';
import { ErrorComponent } from './web/pages/error/error.component';
import { ComingSoonComponent } from './web/pages/coming-soon/coming-soon.component';
import { ProductsListComponent } from './web/pages/products-list/products-list.component';
import { ProductsDetailsComponent } from './web/pages/products-details/products-details.component';
import { CartComponent } from './web/pages/cart/cart.component';
import { CheckoutComponent } from './web/pages/checkout/checkout.component';
import { BlogComponent } from './web/pages/blog/blog.component';
import { BlogDetailsComponent } from './web/pages/blog-details/blog-details.component';
import { ContactComponent } from './web/pages/contact/contact.component';
import { AosDirective } from './shared/directive/aos.directive';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './shared/services/jwt.interceptor';
import { FormsModule } from '@angular/forms';
import { WaitListFormComponent } from './web/components/wait-list-form/wait-list-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectOptionsComponent } from './shared/components/select-options/select-options.component';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { ContactUsDetailsComponent } from './web/components/contact-us-details/contact-us-details.component';
import { TermsOfServiceComponent } from './web/pages/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './web/pages/privacy-policy/privacy-policy.component';
import { AffiliatePageComponent } from './web/pages/affiliates/affiliate-page.component';
import { TextHumanizePipe } from './shared/pipes/text-humanize.pipe';
import { ScholarshipPageComponent } from './web/pages/scholarship/scholarship-page.component';

const materialModules = [
	MatExpansionModule,

	MatRadioModule,
	MatSelectModule,
	MatFormFieldModule,
];

@NgModule({
	declarations: [
		AppComponent,
		PreloaderComponent,
		FooterComponent,
		SaasHomeComponent,
		NavbarOneComponent,
		MarketingHomeComponent,
		StartupHomeComponent,
		SeoAgencyHomeComponent,
		NavbarTwoComponent,
		NavbarThreeComponent,
		NavbarFourComponent,
		AboutComponent,
		ServicesComponent,
		ServicesDetailsComponent,
		ProjectsComponent,
		ProjectsDetailsComponent,
		TeamComponent,
		FaqComponent,
		PricingComponent,
		LoginComponent,
		SignupComponent,
		ErrorComponent,
		ComingSoonComponent,
		ProductsListComponent,
		ProductsDetailsComponent,
		CartComponent,
		CheckoutComponent,
		BlogComponent,
		BlogDetailsComponent,
		ContactComponent,
		AosDirective,
		WaitListFormComponent,
		SelectOptionsComponent,
		ContactUsDetailsComponent,
		TermsOfServiceComponent,
		PrivacyPolicyComponent,
		AffiliatePageComponent,
		ScholarshipPageComponent,
  TextHumanizePipe,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		...materialModules,

		BrowserAnimationsModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
