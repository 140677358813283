import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WaitListService } from '../../../shared/services/wait-list.service';
import {
	WaitlistRecord,
	WaitlistRecordStatus,
} from '../../../shared/models/wait-list.model';
import { NgForm } from '@angular/forms';
import { Config } from '../../../shared/config/config';
import { MixpanelService } from '../../../shared/services/mixpanel.service';
import {
	MatSelectGroup,
	SelectOption,
} from '../../../shared/models/shared.model';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-wait-list-form',
	templateUrl: './wait-list-form.component.html',
	styleUrls: ['./wait-list-form.component.scss'],
})
export class WaitListFormComponent {
	@Input() shortForm = false;
	@Input() showNavBar = true;
	@Input() showContactUsDetails = true;
	waitList: WaitlistRecord = {
		name: null,
		email: null,
		website: null,
		guestCountPerMonth: null,
		center: null,
		notes: null,
		status: WaitlistRecordStatus.DRAFT,
		refferalContact: '',
	};

	aosAnimation = Config.aos.animation;
	aosDuration = Config.aos.duration; //ms
	aosShowDelay = Config.aos.showDelay;
	groups = {
		medical: 'Medical',
		reception: 'Reception',
		others: 'Others',
	};

	matSelectGroup: MatSelectGroup[] = [];
	enumOptions: SelectOption[] = [
		{
			label: 'Guests data management',
			value: 'guests',
			group: this.groups.reception,
		},
		{
			label: 'Bookings',
			value: 'bookings',
			group: this.groups.reception,
		},
		{
			label: 'Automatic emails/SMS',
			value: 'automaticEmails',
			group: this.groups.reception,
		},
		{
			label: 'Medical record',
			value: 'medicalRecord',
			group: this.groups.medical,
		},
		{
			label: 'Ultrasound template',
			value: 'ultrasound',
			group: this.groups.medical,
		},
		{
			label: 'Patient discharge summary',
			value: 'medical-letter',
			group: this.groups.medical,
		},

		{
			label: 'Automatic treatment scheduling',
			value: 'automatic-scheduling',
			group: this.groups.medical,
		},
		{
			label: 'Statistics',
			value: 'statistics',
			group: this.groups.medical,
		},
		{
			label: 'Research',
			value: 'research',
			group: this.groups.medical,
		},
		{
			label: 'Blood tests results integration',
			value: 'blood-tests',
			group: this.groups.medical,
		},
		{
			label: 'Kitchen management',
			value: 'kitchen',
			group: this.groups.others,
		},
		{
			label: 'Inter-department communication',
			value: 'depCommunication',
			group: this.groups.others,
		},
		{
			label: 'Task mamnagement',
			value: 'taskManagement',
			group: this.groups.others,
		},
		{
			label: 'Automatic reminders',
			value: 'automaticReminders',
			group: this.groups.others,
		},
		{
			label: 'Online booking',
			value: 'onlineBooking',
			group: this.groups.reception,
		},
		{
			label: 'Follow-up',
			value: 'follow-up',
			group: this.groups.others,
		},

		{
			label: 'Mobile app',
			value: 'mobileApp',
			group: this.groups.others,
		},
		{
			label: 'Accounting',
			value: 'accounting',
			group: this.groups.others,
		},
	];

	error: string;

	notRequiredField = ['guestCountPerMonth', 'notes', 'website', 'status'];
	successMessage: string;
	emailSaved: string;
	@Output() formSubmitted = new EventEmitter<boolean>();
	email: string;
	mode: 'follow-up-email-opened';
	title: 'Join the waitlist' | 'We want to know more about you' =
		'Join the waitlist';
	subtitle;

	haveARefferalPerson = false;

	constructor(
		private waitListService: WaitListService,
		private mixpanelService: MixpanelService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		for (const item of this.enumOptions) {
			const group = this.matSelectGroup.find(
				(group) => group.name === item['group']
			);
			if (group) {
				group.options.push(item);
			} else {
				this.matSelectGroup.push({
					name: item['group'],
					options: [item],
				});
			}
		}
		this.route.queryParamMap
			.pipe(untilDestroyed(this))
			.subscribe((params) => {
				const emailParam = params.get('email');
				if (emailParam) {
					this.email = emailParam;
				}
				if (!this.shortForm) {
					this.mode = 'follow-up-email-opened';
					this.title = 'We want to know more about you';
					this.subtitle = `Please fill in as much as you're comfortable sharing with us. This info will help us understand you better.`;
					this.mixpanelService.track('Follow up Email Opened', {
						email: this.email,
					});
				}
			});
		this.waitList.email = this.email;
	}

	async submit(form: NgForm) {
		if (this.shortForm && !this.waitList.email) {
			this.error = 'Please enter your email';
			return;
		} else {
			this.error = null;
		}
		let requiredFieldsString: string[] = [];
		// if (form.form.invalid) {
		// 	Object.entries(form.value).forEach((key) => {
		// 		console.log({ key });

		// 		if (!key[1] && !this.notRequiredField.includes(key[0])) {
		// 			requiredFieldsString.push(key[0]);
		// 		}
		// 	});
		// 	// if (!form.form.value.name) {
		// 	// 	requiredFieldsString.push('name');
		// 	// }
		// 	// if (!form.form.value.center) {
		// 	// 	requiredFieldsString.push('center');
		// 	// }
		// 	this.error =
		// 		'Please enter all required fields: ' +
		// 		requiredFieldsString.join(', ');
		// 	console.log(this.error);

		// 	return;
		// }

		try {
			this.waitList.status = WaitlistRecordStatus.SUBMITTED;
			await this.addOrUpdate();
			this.successMessage = this.getSuccessMessage;

			// await this.waitListService.updateOne(
			// 	this.waitList._id,
			// 	this.waitList
			// );
		} catch (error) {
			console.log(error);
		}
	}

	onChange(
		field:
			| 'name'
			| 'email'
			| 'center'
			| 'website'
			| 'guestCountPerMonth'
			| 'actualSolutions'
			| 'notes'
			| 'interestedFeatures'
			| 'otherFeatures'
			| 'submit'
			| 'completeEntireForm'
			| 'actualSolutionsCost'
			| 'position'
			| 'decisionMaker'
			| 'scheduleMeeting'
			| 'scheduleMeetingNotes'
			| 'hasCenter'
			| 'refferalContact'
	) {
		if (field === 'completeEntireForm' && !this.shortForm) return;

		this.addOrUpdate();
		switch (field) {
			case 'name':
				this.mixpanelService.track('Name' + ' Change', {
					'Entire Form': this.waitList,
					name: this.waitList.name,
				});
				break;

			case 'email':
				this.mixpanelService.identify(this.waitList.email);
				this.mixpanelService.track('Email' + ' Change', {
					'Entire Form': this.waitList,
					name: this.waitList.name,
					email: this.waitList.email,
				});
				break;

			case 'completeEntireForm':
				this.mixpanelService.identify(this.waitList.email);
				this.mixpanelService.track(
					'Wanted to fill out the entire form',
					{
						email: this.waitList.email,
					}
				);
				this.shortForm = false;
				this.successMessage = null;
				this.waitList.email = this.emailSaved;

				break;

			case 'center':
				this.mixpanelService.track('Center' + ' Change', {
					'Entire Form': this.waitList,
					center: this.waitList.center,
					email: this.waitList.email,
					name: this.waitList.name,
				});
				break;

			case 'website':
				this.mixpanelService.track('Website' + ' Change', {
					'Entire Form': this.waitList,
					center: this.waitList.center,
					email: this.waitList.email,
					name: this.waitList.name,
					website: this.waitList.website,
				});
				break;

			case 'actualSolutions':
				this.mixpanelService.track('Actual solutions' + ' Change', {
					'Entire Form': this.waitList,
					center: this.waitList.center,
					email: this.waitList.email,
					name: this.waitList.name,
					website: this.waitList.website,
					'Actual solutions': this.waitList.actualSolutions,
				});
				break;

			case 'actualSolutionsCost':
				this.mixpanelService.track(
					'Actual solutions cost' + ' Change',
					{
						'Entire Form': this.waitList,
						center: this.waitList.center,
						email: this.waitList.email,
						name: this.waitList.name,
						website: this.waitList.website,
						'Actual solutions': this.waitList.actualSolutions,
						'Actual solutions cost': this.waitList.actualSolutions,
					}
				);
				break;

			case 'guestCountPerMonth':
				this.mixpanelService.track(
					'Number of guests per month' + ' Change',
					{
						'Entire Form': this.waitList,

						center: this.waitList.center,
						email: this.waitList.email,
						name: this.waitList.name,
						website: this.waitList.website,
						'Number of guests per month':
							this.waitList.guestCountPerMonth,
					}
				);
				break;

			case 'interestedFeatures':
				this.mixpanelService.track(
					'Number of guests per month' + ' Change',
					{
						'Entire Form': this.waitList,
						interestedFeatures: this.waitList.interestedFeatures,
						center: this.waitList.center,
						email: this.waitList.email,
						name: this.waitList.name,
						website: this.waitList.website,
						'Number of guests per month':
							this.waitList.guestCountPerMonth,
					}
				);
				break;

			case 'otherFeatures':
				this.mixpanelService.track('otherFeatures' + ' Change', {
					'Entire Form': this.waitList,
					interestedFeatures: this.waitList.interestedFeatures,
					center: this.waitList.center,
					email: this.waitList.email,
					name: this.waitList.name,
					website: this.waitList.website,
					'Number of guests per month':
						this.waitList.guestCountPerMonth,
					'Other features': this.waitList.otherFeatures,
				});
				break;

			case 'notes':
				this.mixpanelService.track('Notes' + ' Change', {
					'Entire Form': this.waitList,
					center: this.waitList.center,
					email: this.waitList.email,
					name: this.waitList.name,
					website: this.waitList.website,
					notes: this.waitList.notes,
					'Number of guests per month':
						this.waitList.guestCountPerMonth,
					'Other features': this.waitList.otherFeatures,
				});
				break;
			case 'refferalContact':
				this.mixpanelService.track('Refferal contact' + ' Change', {
					'Entire Form': this.waitList,
					center: this.waitList.center,
					email: this.waitList.email,
					name: this.waitList.name,
					website: this.waitList.website,
					notes: this.waitList.notes,
					'Number of guests per month':
						this.waitList.guestCountPerMonth,
					'Other features': this.waitList.otherFeatures,
					'Refferal contact': this.waitList.refferalContact,
				});
				break;
			case 'submit':
				this.mixpanelService.track('Sbmit' + ' Change', {
					'Form Submited!! Hurraaaaaay': true,
					'Entire Form': this.waitList,
					center: this.waitList.center,
					email: this.waitList.email,
					name: this.waitList.name,
					website: this.waitList.website,
					notes: this.waitList.notes,
					'Number of guests per month':
						this.waitList.guestCountPerMonth,
					'Other features': this.waitList.otherFeatures,
				});
				break;

			default:
				break;
		}
	}

	async addOrUpdate() {
		if (this.waitList._id) {
			this.waitList = await this.waitListService.updateOne(
				this.waitList._id,
				this.waitList
			);
		} else {
			this.waitList = await this.waitListService.addOne(this.waitList);
		}
	}

	get getSuccessMessage() {
		if (this.mode === 'follow-up-email-opened') {
			return 'We will be in touch soon!';
		} else if (this.shortForm) {
			return 'Thank you. We will be in touch soon!';
		} else if (!this.shortForm) {
			return `Congrats${this.waitList.name ? ', ' + this.waitList.name + '! ' : '! '
				}We will contact you as soon as possible.`;
		}
	}
	replaceLast = (str, replace) => str.slice(0, -1) + replace;
}
