import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SaasHomeComponent } from './web/pages/saas-home/saas-home.component';
import { MarketingHomeComponent } from './web/pages/marketing-home/marketing-home.component';
import { StartupHomeComponent } from './web/pages/startup-home/startup-home.component';
import { SeoAgencyHomeComponent } from './web/pages/seo-agency-home/seo-agency-home.component';
import { ContactComponent } from './web/pages/contact/contact.component';
import { BlogDetailsComponent } from './web/pages/blog-details/blog-details.component';
import { BlogComponent } from './web/pages/blog/blog.component';
import { CheckoutComponent } from './web/pages/checkout/checkout.component';
import { CartComponent } from './web/pages/cart/cart.component';
import { ProductsDetailsComponent } from './web/pages/products-details/products-details.component';
import { ProductsListComponent } from './web/pages/products-list/products-list.component';
import { ComingSoonComponent } from './web/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './web/pages/error/error.component';
import { SignupComponent } from './web/pages/signup/signup.component';
import { LoginComponent } from './web/pages/login/login.component';
import { PricingComponent } from './web/pages/pricing/pricing.component';
import { FaqComponent } from './web/pages/faq/faq.component';
import { TeamComponent } from './web/pages/team/team.component';
import { ProjectsComponent } from './web/pages/projects/projects.component';
import { ProjectsDetailsComponent } from './web/pages/projects-details/projects-details.component';
import { ServicesComponent } from './web/pages/services/services.component';
import { ServicesDetailsComponent } from './web/pages/services-details/services-details.component';
import { AboutComponent } from './web/pages/about/about.component';
import { WaitListFormComponent } from './web/components/wait-list-form/wait-list-form.component';
import { TermsOfServiceComponent } from './web/pages/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './web/pages/privacy-policy/privacy-policy.component';
import { AffiliatePageComponent } from './web/pages/affiliates/affiliate-page.component';
import { ScholarshipPageComponent } from './web/pages/scholarship/scholarship-page.component';

const routes: Routes = [
	{ path: '', component: SaasHomeComponent },
	{ path: 'demo', component: WaitListFormComponent },
	{ path: 'faq', component: FaqComponent }, 
	{ path: 'marketing', component: MarketingHomeComponent },
	{ path: 'startup', component: StartupHomeComponent },
	{ path: 'seo-agency', component: SeoAgencyHomeComponent },
	{ path: 'about', component: AboutComponent },
	{ path: 'services', component: ServicesComponent },
	{ path: 'services-details', component: ServicesDetailsComponent },
	{ path: 'projects', component: ProjectsComponent },
	{ path: 'projects-details', component: ProjectsDetailsComponent },
	{ path: 'team', component: TeamComponent },
	{ path: 'pricing', component: PricingComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'signup', component: SignupComponent },
	{ path: 'error', component: ErrorComponent },
	{ path: 'coming-soon', component: ComingSoonComponent },
	{ path: 'products-list', component: ProductsListComponent },
	{ path: 'products-details', component: ProductsDetailsComponent },
	{ path: 'cart', component: CartComponent },
	{ path: 'checkout', component: CheckoutComponent },
	{ path: 'blog', component: BlogComponent },
	{ path: 'blog-details', component: BlogDetailsComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'terms-and-conditions', component: TermsOfServiceComponent },
	{ path: 'privacy-policy', component: PrivacyPolicyComponent },
	{ path: 'affiliate', component: AffiliatePageComponent },
	{ path: 'scholarship', component: ScholarshipPageComponent },
	{ path: '**', component: ErrorComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
	exports: [RouterModule],
})
export class AppRoutingModule { }
