<app-navbar-one></app-navbar-one>

<section class="page-container center">
  <h2
    class="title"
    data-aos="fade-up"
    data-aos-duration="800"
    data-aos-delay="300"
  >
    Get them on board and get three cookies
  </h2>
  <h3
    class="subtitle"
    data-aos="fade-up"
    data-aos-duration="800"
    data-aos-delay="300"
  >
    or tell them about us a get one cookie!
  </h3>
  <div
    class="info"
    data-aos="fade-up"
    data-aos-duration="800"
    data-aos-delay="500"
  >
    <!-- Example of referral program https://sucuri.net/guides/referral-program/ -->
    <p>
      Our Referral Program is designed to pay individuals for referring new
      customers directly to us.
    </p>
    <p>
      As an affiliate, you'll have the opportunity to introduce health centers
      to our platform and earn lucrative rewards for every successful referral.
      We pay <b>{{ amount.oneCookie }}</b> for every <b>new</b> referral made
      just using the form below or <b>{{ amount.threeCookies }}</b> for a
      complete sale. Note: referrals are rewarded only if the center ends up
      subscribing.
    </p>
    <p>
      Join us in shaping the future of healthcare technology and together, let's
      empower health centers to thrive in the digital age.
    </p>

    <p>
      <b>How it works:</b>
    </p>

    <ol>
      <li>
        Get in contact with the center and tell them about us. You can hand them
        <a
          href="https://filedn.eu/lJYiP0glwiT0aH4krXqm7au/teamm/assets/TeamM%20brochure.pdf"
          download="TeamM brochure.pdf"
          target="_blank"
          >this brochure</a
        >
        and send them to <a target="_blank" href="../">our website</a>.
      </li>
      <li>
        <h6>One cookie</h6>
        <ul>
          <li>
            Fill in the form below with your information and the center's
            information.
          </li>
          <li>
            We will send them an email with the information you provided, then
            we continue the conversation with them and try to get them on board.
          </li>
          <li>
            If they end up subscribing for a paid plan and you're the first one
            telling them about us, you will receive
            {{ amount.oneCookie }} (1 month after the subscription start).
          </li>
        </ul>
      </li>

      <li>
        <h6>Three cookies</h6>
        Or, if you take on the role of a sales agent and get them to
        <a target="_blank" href="/demo">book a demo</a>
        and sign up for a paid subscription for at least 6 months, you will
        receive
        {{ amount.threeCookies }} (6 months after the subscription start).
      </li>
    </ol>

    <p>
      Ready to get started? Fill in the form below to submit your first
      referral!
    </p>
  </div>
  <div class="form-container">
    <form
      class="form type-1"
      #waitListForm="ngForm"
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay="700"
    >
      <h6>Your information</h6>
      <div class="form-group" *ngFor="let field of ['nameA', 'emailA']">
        <input
          type="text"
          name="{{ field }}"
          required
          class="form-control"
          [(ngModel)]="affiliate[field]"
          (change)="onChange(field)"
          placeholder="{{ field.replace('A', '') | titlecase }}"
        />
      </div>
      <h6>Center information</h6>

      <div
        class="form-group"
        *ngFor="
          let field of [
            'centerName',
            'website',
            'contactPersonName',
            'email',
            'phone'
          ]
        "
      >
        <input
          type="text"
          name="{{ field }}"
          required
          class="form-control"
          [(ngModel)]="affiliate[field]"
          (change)="onChange(field)"
          placeholder="{{ field | textHumanize }}"
        />
      </div>
      <div class="form-group">
        <textarea
          name="otherInfo"
          required
          class="form-control"
          [(ngModel)]="affiliate.otherInfo"
          (change)="onChange('otherInfo')"
          placeholder="Tell us more..."
        ></textarea>
      </div>
      <button
        type="submit"
        (click)="submit(waitListForm)"
        class="btn btn-primary"
      >
        Submit
      </button>
    </form>

    <div
      *ngIf="affiliate.contactPersonName && affiliate.nameA && affiliate.emailA"
    >
      <p>We will send them an email with the information you provided:</p>
      <div class="emailPreview">
        <p>
          Dear <b>{{ affiliate.contactPersonName }}</b
          >,
        </p>
        <p>
          I wanted to reach out to you as we've just been contacted by
          <b>{{ affiliate.nameA }}</b>
          <b> ({{ affiliate.emailA }})</b>, who believes that our software could
          be a valuable addition to your center.
        </p>
        <p>
          At TeamM, we specialize in providing innovative software solutions
          tailored to the needs of health and lifestyle centers. With our robust
          features and user-friendly interface, we're dedicated to helping
          centers like
          <b>{{ affiliate.centerName }}</b> streamline their processes and
          delivering exceptional care to their patients.
        </p>
        <p>
          You may have questions or specific requirements regarding our
          software, and I would be more than happy to provide you with any
          information you need. Book a demo at https://teamm.io/demo and let's
          start a journey together!
        </p>
        <p>Warm regards,</p>
        <p>
          Ema Bolohan<br />
          CEO<br />
          TeamM<br />
        </p>
      </div>
    </div>
  </div>
</section>
