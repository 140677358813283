import { Component, OnInit } from "@angular/core";
import { Config } from "src/app/shared/config/config";
import { Affiliate } from "src/app/shared/models/affiliate.model";
import { MixpanelService } from "src/app/shared/services/mixpanel.service";
import { AffiliateService } from "src/app/shared/services/affiliate.service";

@Component({
  selector: "app-affiliate-page",
  templateUrl: "./affiliate-page.component.html",
  styleUrls: ["./affiliate-page.component.scss"],
})
export class AffiliatePageComponent implements OnInit {
  affiliate: Affiliate = {
    centerName: "",
    website: "",
    contactPersonName: "",
    email: "",
    phone: "",
    otherInfo: "",
    nameA: "",
    emailA: "",
    submitted: false,
  };

  amount = {
    oneCookie: "30€",
    threeCookies: "300€",
  };

  constructor(
    private mixpanelService: MixpanelService,
    private affiliateService: AffiliateService
  ) {}

  ngOnInit(): void {
    this.mixpanelService.track("View Affiliate Page", {});
  }

  aosAnimation = Config.aos.animation;
  aosDuration = Config.aos.duration; //ms
  aosShowDelay = Config.aos.showDelay;

  submit(form: any) {
    if (form.status === "INVALID") {
      return;
    }
    this.affiliate.submitted = true;
    this.saveOrUpdate();
  }
  onChange(field: "name" | "email" | string) {
    this.mixpanelService.track("Refferal Form", {
      ...this.affiliate,
    });
    this.saveOrUpdate();
  }

  async saveOrUpdate() {
    try {
      if (this.affiliate._id) {
        this.affiliateService.updateOne(this.affiliate._id, this.affiliate);
      } else {
        this.affiliate._id = (
          await this.affiliateService.addOne(this.affiliate)
        )._id;
      }
    } catch (error) {
      this.mixpanelService.track("Refferal Form Error", error);
      console.error(error);
    }
  }
}
