import { Component, OnInit } from '@angular/core';
import {
	Router,
	NavigationStart,
	NavigationCancel,
	NavigationEnd,
} from '@angular/router';
import {
	Location,
	LocationStrategy,
	PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import AOS from 'aos';
declare let $: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [
		Location,
		{
			provide: LocationStrategy,
			useClass: PathLocationStrategy,
		},
	],
})
export class AppComponent implements OnInit {
	location: any;
	routerSubscription: any;

	constructor(private router: Router) {}

	ngOnInit() {
		this.recallJsFuntions();
		AOS.init(); //AOS - 2
		AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
	}

	recallJsFuntions() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				$('.preloader').fadeIn('slow');
			}
		});
		this.routerSubscription = this.router.events
			.pipe(
				filter(
					(event) =>
						event instanceof NavigationEnd ||
						event instanceof NavigationCancel
				)
			)
			.subscribe((event) => {
				$.getScript('../assets/js/main.js');
				$('.preloader-area').fadeOut('slow');
				this.location = this.router.url;
				if (!(event instanceof NavigationEnd)) {
					return;
				}
				window.scrollTo(0, 0);
			});
	}
}
