import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-navbar-one',
	templateUrl: './navbar-one.component.html',
	styleUrls: ['./navbar-one.component.scss'],
})
export class NavbarOneComponent implements OnInit {
	@Input() showJoin = true;

	constructor() { }

	ngOnInit(): void { }
}
