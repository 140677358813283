<app-navbar-one></app-navbar-one>

<section class="page-container center">
  <h2
    class="title"
    data-aos="fade-up"
    data-aos-duration="800"
    data-aos-delay="300"
  >
    TeamM Scholarship Program
  </h2>
  <h3
    class="subtitle"
    data-aos="fade-up"
    data-aos-duration="800"
    data-aos-delay="300"
  >
    because nobody gets left behind
  </h3>
  <div
    class="info"
    data-aos="fade-up"
    data-aos-duration="800"
    data-aos-delay="500"
  >
    <p>
      We know money can be an issue sometimes, but that doesn't need to stop you
      from reaching your true potential. We know there are people out there that
      are more than willing to help you, and we want to make it easy for that to
      happen. Will you join our efforts?
    </p>
    <h4>Step 1</h4>
    <h6>If you don't ask, the answer is always no.</h6>
    <p>
      Personalize this email template and send it to 10 potential sponsors from
      your contact list.
    </p>
    <!-- text from https://docs.google.com/document/d/1wybr7uULYQK-nECjAyRs9E48RYj-lVOduz8H3rYWOHU/edit -->
    <div class="emailPreview">
      <p>Dear [Potential Sponsor's Name],</p>
      <p>
        I hope this email finds you well. My name is [Your Name], and I am
        reaching out to you on behalf of [Health Center Name]. We are excited to
        share an opportunity for partnership that aligns with our mission of
        providing exceptional services to our guests.
      </p>
      <p>
        At [Health Center Name], we are dedicated to continuously refining our
        internal operations to better serve our community. After careful
        consideration and research, we have identified a software solution that
        has the potential to significantly enhance our efficiency and
        effectiveness in our purpose.
      </p>
      <p>
        The software we are seeking sponsorship for is designed to streamline
        our administrative processes and optimize overall workflow management.
        By implementing this software, we aim to:
      </p>
      <ul>
        <li>
          Increase operational efficiency, allowing our staff to focus more time
          and attention on patient care.
        </li>
        <li>
          Enhance communication and collaboration among our team members, for a
          better coordination.
        </li>
        <li>
          Improve data management and analytics capabilities, enabling us to
          make more informed decisions and better understand the needs of our
          patients.
        </li>
      </ul>
      <p>
        We firmly believe that this software subscription will not only benefit
        our organization but also contribute to our ability to provide
        high-quality care to our patients. By becoming a sponsor, you will play
        a big role in supporting our mission and helping us achieve our goals.
      </p>

      <p>
        If you are interested in learning more about this sponsorship
        opportunity, please don't hesitate to reach out to me directly at [Your
        Contact Information].
      </p>
      <p>Warm regards,</p>
      <p>
        [Your Name] <br />
        [Your Position]<br />
        [Health Center Name]<br />
        [Your Contact Information]
      </p>
    </div>
    <br />

    <h4>Step 2</h4>
    <h6>Still no sponsors?</h6>
    <p>
      Sign up to use our software's free tier (Front Desk module). Then, your
      center will be made available on our booking engine for lifestyle centers,
      where more people can find you. For each booking, we will get a comission.
      As the number of bookings increase, so will your revenue. As your
      financial situation improves, you will be able to move to using more
      advanced TeamM packages.
    </p>
    <br />

    <h4>Step 3</h4>
    <h6>For those wishing to go all in from the start.</h6>
    <p>
      Submit a sponsorship application directly to us, explaining why you need
      it. To centers that are just starting out, or in financial distress, we
      can provide support for the first year of using TeamM. Whatever you can
      provide, we match.
    </p>
    <p>
      Does this apply to you? Fill in the form below to submit your application!
    </p>
  </div>
</section>
