import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class MixpanelService {
	constructor() {
		this.init();
	}

	init(): void {
		let config = {};
		if (!environment.production) {
			config = { debug: true };
		}
		mixpanel.init(environment.mixpanelToken, config);
	}

	track(id: string, action: mixpanel.Dict): void {
		mixpanel.track(id, action);
	}

	register(props: mixpanel.Dict) {
		mixpanel.register(props);
	}

	peopleSet(props: mixpanel.Dict) {
		mixpanel.people.set(props);
	}

	identify(id: string) {
		mixpanel.identify(id);
	}
}
