import { BaseModel } from './shared.model';

export enum WaitlistRecordStatus {
	SUBMITTED = 'submitted',
	DRAFT = 'draft',
}
export interface WaitlistRecord extends BaseModel {
	email: string;
	name: string;
	guestCountPerMonth?: number;
	website?: string;
	center?: string;
	position?: string;
	decisionMaker?: string;
	notes?: string;
	status: WaitlistRecordStatus;
	interestedFeatures?: string[];
	otherFeatures?: string;
	actualSolutions?: string;
	actualSolutionsCost?: string;
	scheduleMeeting?: boolean;
	scheduleMeetingNotes?: string;
	hasCenter?: boolean;
	refferalName?: string;
	refferalContact?: string;
}
