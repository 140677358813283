<app-navbar-one></app-navbar-one>
<div class="container">
	<h3><strong>Privacy Policy</strong></h3>
	<p><br /></p>
	<p><em>Last updated: 26 Jul 2024</em></p>
	<p><br /></p>
	<p><strong>Introduction</strong></p>
	<p>
		Welcome to EMMROB TECH & FRIENDS S.R.L. ("Company", "we", "our", "us").
		We are committed to protecting your privacy and ensuring that your
		personal information is handled in a safe and responsible manner. This
		Privacy Policy explains how we collect, use, disclose, and safeguard
		your information when you visit our website
		<a href="teamm.io">teamm.io</a>, use our services, or interact with our
		registration forms.
	</p>
	<p><strong>1. Information We Collect</strong></p>
	<p>
		We may collect and process the following types of information about you:
	</p>
	<ul>
		<li>
			<strong>Personal Information</strong>: Name, email address, phone
			number, company name, job title, and other information you provide
			when you register for an account or fill out forms on our site.
		</li>
		<li>
			<strong>Technical Data</strong>: IP address, browser type and
			version, time zone setting, browser plug-in types and versions,
			operating system and platform, and other technology on the devices
			you use to access this website.
		</li>
		<li>
			<strong>Usage Data</strong>: Information about how you use our
			website, products, and services.
		</li>
		<li>
			<strong>Marketing and Communications Data</strong>: Your preferences
			in receiving marketing from us and your communication preferences.
		</li>
	</ul>
	<p><strong>2. How We Use Your Information</strong></p>
	<p>We use the information we collect in the following ways:</p>
	<ul>
		<li>
			<strong>To Provide and Manage Services</strong>: To create and
			manage your account, provide the services you request, and manage
			our relationship with you.
		</li>
		<li>
			<strong>To Improve Our Services</strong>: To better understand how
			you access and use our services, to improve our website, services,
			and user experience.
		</li>
		<li>
			<strong>To Communicate with You</strong>: To send you information,
			updates, and promotional materials about our services. You can
			opt-out of receiving these communications at any time.
		</li>
		<li>
			<strong>To Ensure Security</strong>: To protect our website,
			services, and your information from security threats.
		</li>
		<li>
			<strong>To Comply with Legal Obligations</strong>: To comply with
			applicable laws, regulations, and legal processes.
		</li>
	</ul>
	<p><strong>3. How We Share Your Information</strong></p>
	<p>
		We do not sell, trade, or rent your personal information to others. We
		may share your information in the following circumstances:
	</p>
	<ul>
		<li>
			<strong>Service Providers</strong>: We may share your information
			with trusted third-party service providers who assist us in
			operating our website, conducting our business, or providing
			services to you, as long as those parties agree to keep this
			information confidential.
		</li>
		<li>
			<strong>Legal Requirements</strong>: We may disclose your
			information if required to do so by law or in response to valid
			requests by public authorities (e.g., a court or a government
			agency).
		</li>
		<li>
			<strong>Business Transfers</strong>: If we are involved in a merger,
			acquisition, or sale of all or a portion of our assets, your
			information may be transferred as part of that transaction.
		</li>
	</ul>
	<p><strong>4. Data Security</strong></p>
	<p>
		We use appropriate technical and organizational measures to protect your
		personal information from unauthorized access, use, disclosure,
		alteration, or destruction. However, no internet or email transmission
		is ever fully secure or error-free, so please take special care in
		deciding what information you send to us in this way.
	</p>
	<p><strong>5. Your Data Protection Rights</strong></p>
	<p>
		Depending on your location, you may have the following rights regarding
		your personal information:
	</p>
	<ul>
		<li>
			<strong>Access</strong>: You have the right to request a copy of the
			personal information we hold about you.
		</li>
		<li>
			<strong>Correction</strong>: You have the right to request that we
			correct any information you believe is inaccurate or complete
			information you believe is incomplete.
		</li>
		<li>
			<strong>Deletion</strong>: You have the right to request that we
			delete your personal information, under certain conditions.
		</li>
		<li>
			<strong>Restriction of Processing</strong>: You have the right to
			request that we restrict the processing of your personal
			information, under certain conditions.
		</li>
		<li>
			<strong>Objection to Processing</strong>: You have the right to
			object to our processing of your personal information, under certain
			conditions.
		</li>
		<li>
			<strong>Data Portability</strong>: You have the right to request
			that we transfer the data that we have collected to another
			organization, or directly to you, under certain conditions.
		</li>
	</ul>
	<p><strong>6. Third-Party Links</strong></p>
	<p>
		Our website may contain links to other websites operated by third
		parties. This Privacy Policy applies only to our website, so if you
		click on a link to another website, you should read their privacy
		policy.
	</p>
	<p><strong>7. Changes to This Privacy Policy</strong></p>
	<p>
		We may update this Privacy Policy from time to time. We will notify you
		of any changes by posting the new Privacy Policy on our website. You are
		advised to review this Privacy Policy periodically for any changes.
	</p>
	<p><strong>8. Contact Us</strong></p>
	<p>
		If you have any questions about this Privacy Policy, please contact us
		at:
	</p>
	<p>EMMROB TECH & FRIENDS S.R.L</p>
	<!-- <p>[Your Address]</p> -->
	<p>contact@teamm.io</p>
	<!-- <p>[Your Phone Number]</p> -->
	<p>
		Thank you for choosing EMMROB TECH & FRIENDS. We value your privacy and
		are committed to protecting your personal information.
	</p>
</div>
