<div class="talk-content-area info">
	<span [attr.data-aos]="aosAnimation" [attr.data-aos-duration]="aosDuration"
		[attr.data-aos-delay]="aosShowDelay.slow" class="accent-text">Have any queries?</span>
	<h3 [attr.data-aos]="aosAnimation" [attr.data-aos-duration]="aosDuration" [attr.data-aos-delay]="aosShowDelay.slow">
		Let’s Talk
	</h3>
	<p [attr.data-aos]="aosAnimation" [attr.data-aos-duration]="aosDuration" [attr.data-aos-delay]="aosShowDelay.slow">
		Are you interested? Do you need more details? Set up a meeting with us
		to discuss.
	</p>

	<div class="talk-info">
		<h3 [attr.data-aos]="aosAnimation" [attr.data-aos-duration]="aosDuration"
			[attr.data-aos-delay]="aosShowDelay.slow">
			{{Constants.email}}
		</h3>
		<br />
		<p [attr.data-aos]="aosAnimation" [attr.data-aos-duration]="aosDuration"
			[attr.data-aos-delay]="aosShowDelay.slow">
			For urgent matters, write to us on WhatsApp:
		</p>
		<h3 [attr.data-aos]="aosAnimation" [attr.data-aos-duration]="aosDuration"
			[attr.data-aos-delay]="aosShowDelay.slow">
			{{Constants.phone}}
		</h3>
		<br />
		<p [attr.data-aos]="aosAnimation" [attr.data-aos-duration]="aosDuration"
			[attr.data-aos-delay]="aosShowDelay.slow">
			Check out our brochure to find out more about TeamM.
			<a href="https://filedn.eu/lJYiP0glwiT0aH4krXqm7au/teamm/assets/TeamM%20brochure.pdf"
				download="TeamM brochure.pdf" target="_blank">Download pdf brochure</a>
		</p>
	</div>
</div>