export const Split = {
  colors: "|",
  options: "#",
  newOption: "|||",
  wrapKeyBefore: "{{",
  wrapKeyAfter: "}}",
};

export const ConstantsToDo = {
  // not really constants, they should be set on center
  dateFormats: {
    ro: "DD.MM.YYYY",
    en: "MM/DD/YYYY",
  },
};
export declare type styleInput = "default" | "outline" | "fill";
declare type styleSelect = "default" | "outline" | "fill";
declare type styleDate = "default" | "outline" | "fill";

interface Style {
  input: styleInput;
  select: styleSelect;
  date: styleDate;
}
export class Constants {
  static appName = "TeamM";
  static email = "contact@teamm.io";
  static phone = "+40 750 454 123";
  static address = "Pocioveliste 10A, Bihor, Romania, 417224";
  static style: Style = {
    input: "fill",
    select: "fill",
    date: "fill",
    // input: 'outline',
    // select: 'outline',
    // date: 'outline',
  };

  static matTooltipShowDelay = 500;
  static matTooltipShowDelayFast = 100;
}
