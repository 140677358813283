import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { HttpClient } from '@angular/common/http';
import { Affiliate } from '../models/affiliate.model';

@Injectable({
	providedIn: 'root',
})
export class AffiliateService extends CrudService<Affiliate> {
	constructor(http: HttpClient) {
		super(http, 'affiliates');
	}
}
