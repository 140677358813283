<app-navbar-one></app-navbar-one>
<!-- <div class="detected-elms">
	Detected<br />
	----------
	<div *ngFor="let name of detectedElms">{{ name }}</div>
</div> -->

<div #topSection id="topSection" class="saas-home-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="hero-content">
              <span
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="300"
              >
                A software built for your lifestyle center
              </span>

              <h1
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="500"
              >
                Heartwork, not paperwork.
                <!-- Focus on
								<span class="strikethrough">paperwork</span>
								heartwork -->
              </h1>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="600"
                class="mb-4"
              >
                Experience the power of automations in your team
              </p>

              <!-- 
							<div class="saas-btn">
								<a routerLink="/pricing" class="saas-btn-one"
									>Start Free Trial
									<i class="fa fa-arrow-right"></i
								></a>
							</div> -->
            </div>
          </div>

          <div
            class="sticky-section"
            *ngIf="isMobile || showMedia(SaasSection.TOP_SECTION)"
            [attr.data-aos]="this.isMobile ? aosAnimation : aosMedia.animation"
            [attr.data-aos-duration]="
              this.isMobile ? aosDuration + 300 : aosMedia.duration
            "
            [attr.data-aos-delay]="
              this.isMobile ? aosShowDelay.slow + 500 : aosMedia.delay
            "
          >
            <div class="col-lg-6 col-md-12">
              <div class="saas-image multiple">
                <img
                  src="../../../../assets/img/screen-shots/table.png"
                  alt="image"
                />
                <img
                  src="../../../../assets/img/screen-shots/therapeutic-plan.png"
                  alt="image"
                />
                <img
                  class="is-mobile"
                  src="../../../../assets/img/screen-shots/mobile-schedule-2.png"
                  alt="image"
                />
              </div>
            </div>

            <div class="circle-img">
              <img src="assets/img/saas-banner/circle.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isMobile">
    <!-- SVG floating shapes  -->
    <div *ngFor="let shape of [1]" class="shape-img{{ shape }}">
      <img
        src="../../../../assets/img/shape/shape{{ shape }}.svg"
        alt="image"
      />
    </div>

    <!-- PNG floating shapes  -->
    <div *ngFor="let shape of [3, 4, 6, 7]" class="shape-img{{ shape }}">
      <img
        src="../../../../assets/img/shape/shape{{ shape }}.png"
        alt="image"
      />
    </div>
  </ng-container>
</div>

<!-- ARES COVERED  -->
<section #areasSection id="areasSection" class="features-section ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="features-area-content">
          <!-- <span data-aos="fade-up" data-aos-duration="1000"
						>Features</span
					> -->
          <h1
            class="saas-subtitle"
            [attr.data-aos]="aosAnimation"
            [attr.data-aos-duration]="aosDuration"
            [attr.data-aos-delay]="aosShowDelay.slow"
          >
            Areas covered
          </h1>
          <p
            [attr.data-aos]="aosAnimation"
            [attr.data-aos-duration]="aosDuration"
            [attr.data-aos-delay]="aosShowDelay.slow"
          >
            Is there an area that you need and you don't see it here? Contact us
            and let's discuss it!
          </p>

          <div class="row">
            <div
              *ngFor="let area of areas; let i = index"
              class="col-lg-6 col-md-6 col-sm-6"
              [attr.data-aos]="aosAnimation"
              [attr.data-aos-duration]="aosDuration"
              [attr.data-aos-delay]="aosShowDelay.slow + i * 100"
            >
              <div class="features-text benefit-area-content bg-f4ffb3">
                <div class="icon">
                  <img
                    src="../../../../assets/img/services/{{ area.logo }}.png"
                  />
                </div>
                <h3>{{ area.name }}</h3>
                <p>
                  {{ area.text }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="sticky-section"
            *ngIf="isMobile || showMedia(SaasSection.AREAS_SECTION)"
            [attr.data-aos]="this.isMobile ? aosAnimation : aosMedia.animation"
            [attr.data-aos-duration]="
              this.isMobile ? aosDuration + 300 : aosMedia.duration
            "
            [attr.data-aos-delay]="
              this.isMobile ? aosShowDelay.slow + 500 : aosMedia.delay
            "
          >
            <div class="col-lg-6 col-md-12">
              <div class="saas-image multiple">
                <img
                  src="../../../../assets/img/screen-shots/procedures.png"
                  alt="image"
                />
                <img
                  src="../../../../assets/img/screen-shots/recommendations.png"
                  alt="image"
                />
                <img
                  src="../../../../assets/img/screen-shots/room-assign.png"
                  alt="image"
                />
              </div>
            </div>
            <!-- <div class="features-image">
							<div class="features-shape">
								<img
									src="../../../../assets/img/talk/talk-img1.png"
									[attr.data-aos]="aosShape.animation"
									[attr.data-aos-duration]="aosShape.duration"
									[attr.data-aos-delay]="aosShape.delay"
									class="media-shape"
									alt="image"
								/>
							</div>

							<div class="features-arrow">
								<img
									src="../../../../assets/img/features/features-img2.png"
									[attr.data-aos]="aosShape.animation"
									[attr.data-aos-duration]="aosShape.duration"
									[attr.data-aos-delay]="aosShape.delay"
									alt="image"
								/>
							</div>
						</div> -->
          </div>
          <!-- <div class="features-btn">
						<a routerLink="/about" class="default-btn"
							>Learn More <i class="fa fa-arrow-right"></i
						></a>
					</div> -->
        </div>
      </div>
    </div>
  </div>
</section>

<!-- FEATURES  -->
<section
  #featuresSection
  id="featuresSection"
  class="features-section video-container ptb-100"
>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="features-area-content">
          <!-- <span data-aos="fade-up" data-aos-duration="1000"
						>Features</span
					> -->
          <h1
            class="saas-subtitle"
            [attr.data-aos]="aosAnimation"
            [attr.data-aos-duration]="aosDuration"
            [attr.data-aos-delay]="aosShowDelay.slow"
          >
            Features
          </h1>
          <!-- <p
						data-aos="fade-up"
						data-aos-duration="500"
						data-aos-delay="500"
					>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit,
						sed do eiusmod tempor labore suspendisse ultrices
						gravida.
					</p> -->

          <div class="row">
            <div
              *ngFor="let feature of features; let i = index"
              class="col-lg-6 col-md-6 col-sm-6"
              [attr.data-aos]="aosAnimation"
              [attr.data-aos-duration]="aosDuration"
              [attr.data-aos-delay]="aosShowDelay.slow + i * 100"
            >
              <div class="features-text benefit-area-content bg-f4ffb3">
                <div class="icon">
                  <img
                    src="../../../../assets/img/features/{{ feature.logo }}.png"
                  />
                </div>
                <h3>{{ feature.name }}</h3>
                <p>
                  {{ feature.text }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="sticky-section"
            *ngIf="isMobile || showMedia(SaasSection.FEATURES_SECTION)"
            [attr.data-aos]="this.isMobile ? aosAnimation : aosMedia.animation"
            [attr.data-aos-duration]="
              this.isMobile ? aosDuration + 300 : aosMedia.duration
            "
            [attr.data-aos-delay]="
              this.isMobile ? aosShowDelay.slow + 500 : aosMedia.delay
            "
          >
            <div class="width-50">
              <div
                [attr.data-aos]="aosAnimation"
                [attr.data-aos-duration]="aosDuration + 300"
                [attr.data-aos-delay]="aosShowDelay.slow"
              >
                <div class="saas-image multiple">
                  <img
                    src="../../../../assets/img/screen-shots/table.png"
                    alt="image"
                  />
                  <img
                    src="../../../../assets/img/screen-shots/recommendations.png"
                    alt="image"
                  />
                  <img
                    src="../../../../assets/img/screen-shots/export.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="features-btn">
						<a routerLink="/about" class="default-btn"
							>Learn More <i class="fa fa-arrow-right"></i
						></a>
					</div> -->
        </div>
      </div>
    </div>
  </div>
</section>

<!-- VIDEO  -->
<section #demoVideo id="demoVideo" class="features-section video-container">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="features-area-content">
          <!-- <span data-aos="fade-up" data-aos-duration="1000"
						>Features</span
					> -->
          <h1
            class="saas-subtitle"
            [attr.data-aos]="aosAnimation"
            [attr.data-aos-duration]="aosDuration"
            [attr.data-aos-delay]="aosShowDelay.slow"
          >
            See it in action
          </h1>
          <p>
            Watch a short demo to see what your lifestyle center can become.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="sticky-section"
    *ngIf="isMobile || showMedia(SaasSection.DEMO_VIDEO)"
  >
    <div class="w-100">
      <div
        class="container media video"
        [attr.data-aos]="aosAnimation"
        [attr.data-aos-duration]="aosDuration + 300"
        [attr.data-aos-delay]="aosShowDelay.slow"
      >
        <div class="video-content">
          <a
            href="https://www.youtube.com/watch?v=lvgCAk_wgsg"
            (click)="clickedYoutube()"
            class="video-btn popup-youtube"
            ><i class="fas fa-play"></i
          ></a>
        </div>
        <div class="features-shape">
          <img
            src="../../../../assets/img/talk/talk-img1.png"
            [attr.data-aos]="aosShape.animation"
            [attr.data-aos-duration]="aosShape.duration"
            [attr.data-aos-delay]="aosShape.delay"
            class="media-shape"
            alt="image"
          />
        </div>

        <div class="features-arrow">
          <img
            src="../../../../assets/img/features/features-img2.png"
            [attr.data-aos]="aosShape.animation"
            [attr.data-aos-duration]="aosShape.duration"
            [attr.data-aos-delay]="aosShape.delay"
            alt="image"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- FORM  -->
<section #join id="joinSection" class="pt-50">
  <app-wait-list-form
    data-aos="fade-up"
    data-aos-duration="800"
    data-aos-delay="700"
    [shortForm]="true"
    [showNavBar]="false"
    (formSubmitted)="scrollTo(join)"
    [showContactUsDetails]="false"
    [showNavBar]="false"
  >
    <div
      class="col-lg-6 col-md-12"
      class="sticky-section"
      *ngIf="isMobile || showMedia(SaasSection.WAITLIST_FORM)"
    >
      <app-contact-us-details></app-contact-us-details>
    </div>
  </app-wait-list-form>
</section>

<section #faqsSection class="pb-100" id="faqsSection">
  <app-faq></app-faq>
</section>

<!-- <app-footer> 	</app-footer> -->

<!-- <section class="benefit-section pb-70">
	<div class="container">
		<div class="section-title">
			<h2
				[attr.data-aos]="aosAnimation"
				[attr.data-aos-duration]="aosDuration"
				[attr.data-aos-delay]="aosShowDelay.slow"
			>
				Some features
			</h2>
			<p
				[attr.data-aos]="aosAnimation"
				[attr.data-aos-duration]="aosDuration"
				[attr.data-aos-delay]="aosShowDelay.slow"
			>
				Lorem ipsum dolor sit amet consectetur adipisicing elit.
				Voluptatem voluptas sed magni voluptate, unde atque, quis
				voluptatum quas quasi nobis consectetur. Recusandae rem repellat
				nostrum dolore ipsum.
			</p>
		</div>

		<div class="row">
			<div
				*ngFor="let feature of features; let i = index"
				[attr.data-aos]="aosAnimation"
				[attr.data-aos-duration]="aosDuration"
				[attr.data-aos-delay]="aosShowDelay.slow + i * 100"
				class="col-lg-6 col-md-6"
			>
				<div class="benefit-area-content">
					<i>
						<img
							class="logo-width"
							src="../../../../assets/img/features/{{
								feature.logo
							}}.png"
						/>
					</i>
					<h3>{{ feature.name }}</h3>
					<p>
						{{ feature.text }}
					</p>
				</div>
			</div>
		</div>
	</div>
</section> -->

<!-- <section class="fun-facts-area pt-100 pb-70">
	<div class="container">
		<div class="row">
			<div class="col-lg-3 col-sm-6">
				<div class="single-fun-fact">
					<h3>
						<span class="odometer" data-count="100">00</span
						><span class="sign-icon">%</span>
					</h3>
					<p>Completed projects</p>
				</div>
			</div>

			<div class="col-lg-3 col-sm-6">
				<div class="single-fun-fact">
					<h3>
						<span class="odometer" data-count="253">00</span
						><span class="sign-icon">+</span>
					</h3>
					<p>Customer satisfaction</p>
				</div>
			</div>

			<div class="col-lg-3 col-sm-6">
				<div class="single-fun-fact">
					<h3>
						<span class="odometer" data-count="24">00</span
						><span class="sign-icon">h</span>
					</h3>
					<p>Expert Support Team</p>
				</div>
			</div>

			<div class="col-lg-3 col-sm-6">
				<div class="single-fun-fact">
					<h3>
						<span class="odometer" data-count="78">00</span
						><span class="sign-icon">k</span>
					</h3>
					<p>Sales in Count</p>
				</div>
			</div>
		</div>
	</div>
</section> -->

<!-- 
<section class="support-section pb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="support-image">
					<img
						src="assets/img/support/support-img1.png"
						alt="image"
					/>

					<div class="support-shape">
						<img
							src="assets/img/support/support-img2.png"
							class="wow animate__animated animate__fadeInUp"
							data-wow-delay="0.6s"
							alt="image"
						/>
					</div>

					<div class="support-arrow">
						<img
							src="assets/img/support/support-img3.png"
							class="wow animate__animated animate__fadeInRight"
							data-wow-delay="0.6s"
							alt="image"
						/>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-12">
				<div class="support-area-content">
					<span>The support</span>
					<h3>Management is easy when getting support properly</h3>
					<p>
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Repellat, temporibus autem! Provident, soluta quod
						atque libero magnam accusamus quasi inventore enim
						accusamus quasi laboriosam voluptates laudantium
						veritatis aliquid labore error molestiae nulla?
					</p>
					<p>
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Pariatur, impedit debitis! Ipsum ratione dolore
						sit numquam dicta ex officiis accusantium accusamus iure
						nihil? Accusamus aspernatur harum provident.
					</p>

					<div class="support-btn">
						<a routerLink="/" class="default-btn"
							>Learn More <i class="fa fa-arrow-right"></i
						></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->

<!-- <section class="software-section pb-100">
	<div class="container">
		<div class="software-title">
			<h2>We're a software development Meet our updated screen</h2>
		</div>

		<div class="tab software-list-tab">
			<ul class="tabs">
				<li>
					<a href="#"
						><i class="flaticon-budget"></i> Budget Overview</a
					>
				</li>
				<li>
					<a href="#"><i class="flaticon-data"></i> Integrations</a>
				</li>
				<li>
					<a href="#"><i class="flaticon-file"></i> View Reports</a>
				</li>
				<li>
					<a href="#"
						><i class="flaticon-ruler"></i> Create & Adjust</a
					>
				</li>
			</ul>

			<div class="tab_content">
				<div class="tabs_item">
					<div class="tab-image">
						<img
							src="assets/img/software-tab/software-img1.png"
							alt="image"
						/>

						<div class="tab-shape">
							<img
								src="assets/img/software-tab/software-img2.png"
								class="wow animate__animated animate__fadeInUp"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>

						<div class="tab-arrow">
							<img
								src="assets/img/software-tab/software-img3.png"
								class="wow animate__animated animate__fadeInLeft"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>

						<div class="tab-arrow-two">
							<img
								src="assets/img/software-tab/software-img3.png"
								class="wow animate__animated animate__fadeInRight"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>
					</div>
				</div>

				<div class="tabs_item">
					<div class="tab-image">
						<img
							src="assets/img/software-tab/software-img1.png"
							alt="image"
						/>

						<div class="tab-shape">
							<img
								src="assets/img/software-tab/software-img2.png"
								class="wow animate__animated animate__fadeInUp"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>

						<div class="tab-arrow">
							<img
								src="assets/img/software-tab/software-img3.png"
								class="wow animate__animated animate__fadeInLeft"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>

						<div class="tab-arrow-two">
							<img
								src="assets/img/software-tab/software-img3.png"
								class="wow animate__animated animate__fadeInRight"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>
					</div>
				</div>

				<div class="tabs_item">
					<div class="tab-image">
						<img
							src="assets/img/software-tab/software-img1.png"
							alt="image"
						/>

						<div class="tab-shape">
							<img
								src="assets/img/software-tab/software-img2.png"
								class="wow animate__animated animate__fadeInUp"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>

						<div class="tab-arrow">
							<img
								src="assets/img/software-tab/software-img3.png"
								class="wow animate__animated animate__fadeInLeft"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>

						<div class="tab-arrow-two">
							<img
								src="assets/img/software-tab/software-img3.png"
								class="wow animate__animated animate__fadeInRight"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>
					</div>
				</div>

				<div class="tabs_item">
					<div class="tab-image">
						<img
							src="assets/img/software-tab/software-img1.png"
							alt="image"
						/>

						<div class="tab-shape">
							<img
								src="assets/img/software-tab/software-img2.png"
								class="wow animate__animated animate__fadeInUp"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>

						<div class="tab-arrow">
							<img
								src="assets/img/software-tab/software-img3.png"
								class="wow animate__animated animate__fadeInLeft"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>

						<div class="tab-arrow-two">
							<img
								src="assets/img/software-tab/software-img3.png"
								class="wow animate__animated animate__fadeInRight"
								data-wow-delay="0.6s"
								alt="image"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->

<!-- <section class="pricing-section pb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="pricing-area-content">
					<span>Pricing plan</span>
					<h3>Our Pricing is Affordable To all customer</h3>
					<p>
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Repellat, temporibus autem! Provident, soluta quod
						atque libero magnam accusamus quasi inventore enim
						accusamus quasi laboriosam voluptates laudantium
						veritatis aliquid labore error molestiae nulla?
					</p>
					<p>
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Pariatur, impedit debitis! Ipsum ratione dolore
						sit numquam dicta ex officiis accusantium accusamus iure
						nihil? Accusamus aspernatur harum provident
					</p>
				</div>

				<div class="pricing-btn-wrap">
					<div class="price-btn">
						<a routerLink="/pricing" class="price-btn-one"
							>Pricing Plan <i class="fa fa-arrow-right"></i
						></a>
					</div>

					<div class="price-btn">
						<a routerLink="/pricing" class="price-btn-one price-two"
							>Annual Plan <i class="fa fa-arrow-right"></i
						></a>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-pricing-box">
					<div class="pricing-header">
						<h3>Bussines Account</h3>
					</div>

					<div class="price">$56/ <span>Monthly</span></div>

					<ul class="pricing-features">
						<li>
							<i class="fa fa-arrow-right"></i> Drug & Drop
							builder
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 1009s account
							ready
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Unlimited Email
						</li>
						<li><i class="fa fa-arrow-right"></i> Blog tools</li>
						<li>
							<i class="fa fa-arrow-right"></i> Ecommerce store
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 40+ Webmaster
							tools
						</li>
					</ul>

					<div class="buy-btn">
						<a routerLink="/" class="buy-btn-one"
							>Create a Free Account</a
						>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-pricing-box table-price">
					<div class="pricing-header">
						<h3>Standard Account</h3>
					</div>

					<div class="price">$70/ <span>Monthly</span></div>

					<ul class="pricing-features">
						<li>
							<i class="fa fa-arrow-right"></i> Drug & Drop
							builder
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 1009s account
							ready
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Unlimited Email
						</li>
						<li><i class="fa fa-arrow-right"></i> Blog tools</li>
						<li>
							<i class="fa fa-arrow-right"></i> Ecommerce store
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 40+ Webmaster
							tools
						</li>
					</ul>

					<div class="buy-btn">
						<a routerLink="/" class="buy-btn-one"
							>Start a Free trial</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="client-section ptb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-5 col-md-12">
				<div class="client-image">
					<img src="assets/img/client/client-img1.png" alt="image" />

					<div class="client-shape">
						<img
							src="assets/img/client/client-img2.png"
							class="wow animate__animated animate__fadeInUp"
							data-wow-delay="0.6s"
							alt="image"
						/>
					</div>
				</div>
			</div>

			<div class="col-lg-7 col-md-12">
				<div class="client-wrap owl-carousel owl-theme">
					<div class="client-content-area">
						<h3>Jonah Dew</h3>
						<span>Flagstone Company Canada</span>
						<p>
							Quis ipsum suspendisse ultrices gravida. Risus
							commodo viverra maecenas accumsan lacus vel
							facilisis. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt
							aliqua.
						</p>

						<div class="client-logo">
							<img
								src="assets/img/client/client-img3.png"
								alt="image"
							/>
						</div>
					</div>

					<div class="client-content-area">
						<h3>Anderson Jonah</h3>
						<span>Flagstone Company USA</span>
						<p>
							Quis ipsum suspendisse ultrices gravida. Risus
							commodo viverra maecenas accumsan lacus vel
							facilisis. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt
							aliqua.
						</p>

						<div class="client-logo">
							<img
								src="assets/img/client/client-img3.png"
								alt="image"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="subscribe-section ptb-100">
	<div class="container-fluid pr-0">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="subscribe-area-content">
					<span>Email address</span>
					<h3>Subscribe our monthly newsletter to get updated</h3>
					<p>
						Don’t be afraid your mail is secure it no will be shared
						anywhere or everywhere.
					</p>

					<form class="newsletter-form">
						<input
							type="email"
							class="input-newsletter"
							placeholder="Enter your email"
							name="EMAIL"
						/>
						<button type="submit">
							Submit now <i class="fa fa-arrow-right"></i>
						</button>
					</form>

					<ul class="social-list">
						<li class="list-heading">Follow us for update:</li>
						<li>
							<a href="#" class="facebook" target="_blank"
								><i class="fab fa-facebook-f"></i
							></a>
						</li>
						<li>
							<a href="#" class="twitter" target="_blank"
								><i class="fab fa-twitter"></i
							></a>
						</li>
						<li>
							<a href="#" class="linkedin" target="_blank"
								><i class="fab fa-linkedin-in"></i
							></a>
						</li>
						<li>
							<a href="#" class="instagram" target="_blank"
								><i class="fab fa-instagram"></i
							></a>
						</li>
					</ul>
				</div>
			</div>

			<div class="col-lg-6 col-md-12 pr-0">
				<div class="subscribe-image">
					<div class="main-image">
						<img
							src="assets/img/subscribe/subscribe-img5.png"
							class="wow animate__animated animate__fadeInUp"
							data-wow-delay="0.7s"
							alt="image"
						/>
						<img
							src="assets/img/subscribe/subscribe-img2.png"
							class="wow animate__animated animate__fadeInLeft"
							data-wow-delay="1s"
							alt="image"
						/>
						<img
							src="assets/img/subscribe/subscribe-img3.png"
							class="wow animate__animated animate__fadeInLeft"
							data-wow-delay="1.3s"
							alt="image"
						/>
						<img
							src="assets/img/subscribe/subscribe-img4.png"
							class="wow animate__animated animate__fadeInRight"
							data-wow-delay="1s"
							alt="image"
						/>
					</div>

					<div class="subscribe-mobile-image">
						<img
							src="assets/img/subscribe/pic.png"
							class="wow animate__animated animate__zoomIn"
							data-wow-delay="0.7s"
							alt="image"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="partner-section pb-100">
	<div class="container">
		<div class="partner-slider owl-carousel owl-theme">
			<div class="partner-item">
				<a href="#" target="_blank" class="d-block"
					><img
						src="assets/img/partnar/partner-img1.png"
						alt="partner"
				/></a>
			</div>

			<div class="partner-item">
				<a href="#" target="_blank" class="d-block"
					><img
						src="assets/img/partnar/partner-img2.png"
						alt="partner"
				/></a>
			</div>

			<div class="partner-item">
				<a href="#" target="_blank" class="d-block"
					><img
						src="assets/img/partnar/partner-img3.png"
						alt="partner"
				/></a>
			</div>

			<div class="partner-item">
				<a href="#" target="_blank" class="d-block"
					><img
						src="assets/img/partnar/partner-img4.png"
						alt="partner"
				/></a>
			</div>

			<div class="partner-item">
				<a href="#" target="_blank" class="d-block"
					><img
						src="assets/img/partnar/partner-img5.png"
						alt="partner"
				/></a>
			</div>
		</div>
	</div>
</div> -->
