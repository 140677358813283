<app-navbar-one [showJoin]="false" *ngIf="showNavBar"></app-navbar-one>

<section id="waitList" class="talk-section ptb-100 pb-100">
  <div class="container">
    <div style="height: 3rem; width: 2rem"></div>
    <div class="row align-items-center">
      <h2
        class="saas-subtitle"
        [attr.data-aos]="aosAnimation"
        [attr.data-aos-duration]="aosDuration"
        [attr.data-aos-delay]="aosShowDelay.slow"
      >
        {{ title }}
      </h2>
      <p *ngIf="subtitle">{{ subtitle }}</p>
      <div
        class="col-lg-6 col-md-12"
        [attr.data-aos]="aosAnimation"
        [attr.data-aos-duration]="aosDuration"
        [attr.data-aos-delay]="aosShowDelay.slow"
      >
        <div class="talk-form">
          <form
            (onsubmit)="$event.preventDefault()"
            #waitListForm="ngForm"
            method="post"
          >
            <div class="form-group" *ngIf="!shortForm && !successMessage">
              <input
                type="text"
                name="name"
                required
                class="form-control"
                [(ngModel)]="waitList.name"
                (change)="onChange('name')"
                placeholder="Your name"
                [attr.data-aos]="aosAnimation"
                [attr.data-aos-duration]="aosDuration"
                [attr.data-aos-delay]="aosShowDelay.slow"
              />
            </div>

            <div class="form-group" *ngIf="!successMessage">
              <input
                type="email"
                [attr.data-aos]="aosAnimation"
                [attr.data-aos-duration]="aosDuration"
                [attr.data-aos-delay]="aosShowDelay.slow"
                (change)="onChange('email')"
                required
                name="email"
                [(ngModel)]="waitList.email"
                class="form-control"
                placeholder="Your email address"
              />
            </div>
            <ng-container *ngIf="!shortForm && !successMessage">
              <div class="col-lg-12 col-md-12 mb-4">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="hasCenter"
                    name="hasCenter"
                    [attr.data-aos]="aosAnimation"
                    [attr.data-aos-duration]="aosDuration"
                    [attr.data-aos-delay]="aosShowDelay.slow"
                    [(ngModel)]="waitList.hasCenter"
                    [checked]="waitList.hasCenter"
                    (change)="onChange('hasCenter')"
                  />
                  <label
                    class="form-check-label"
                    for="hasCenter"
                    [attr.data-aos]="aosAnimation"
                    [attr.data-aos-duration]="aosDuration"
                    [attr.data-aos-delay]="aosShowDelay.slow"
                    >Do you already have a center/organization?</label
                  >
                </div>
              </div>

              <ng-container *ngIf="waitList.hasCenter">
                <div class="form-group">
                  <input
                    type="text"
                    [attr.data-aos]="aosAnimation"
                    [attr.data-aos-duration]="aosDuration"
                    [attr.data-aos-delay]="aosShowDelay.slow"
                    name="center"
                    [(ngModel)]="waitList.center"
                    (change)="onChange('center')"
                    class="form-control"
                    placeholder="Name and country of your center (Required)"
                  />
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    name="website"
                    [attr.data-aos]="aosAnimation"
                    [attr.data-aos-duration]="aosDuration"
                    [attr.data-aos-delay]="aosShowDelay.slow"
                    [(ngModel)]="waitList.website"
                    (change)="onChange('website')"
                    class="form-control"
                    placeholder="Your organization's website"
                  />
                </div>

                <!-- <div class="form-group">
									<input
										[attr.data-aos]="aosAnimation"
										[attr.data-aos-duration]="aosDuration"
										[attr.data-aos-delay]="
											aosShowDelay.slow
										"
										type="number"
										pattern="\d*"
										inputmode="decimal"
										min="0"
										step="1"
										(change)="
											onChange('guestCountPerMonth')
										"
										name="guestCountPerMonth"
										[(ngModel)]="
											waitList.guestCountPerMonth
										"
										class="form-control"
										placeholder="Number of guests per month (average)"
									/>
								</div> -->

                <!-- <div class="form-group">
									<textarea
										name="actualSolutions"
										class="form-control"
										(change)="onChange('actualSolutions')"
										cols="20"
										[attr.data-aos]="aosAnimation"
										[attr.data-aos-duration]="aosDuration"
										[attr.data-aos-delay]="
											aosShowDelay.slow
										"
										[(ngModel)]="waitList.actualSolutions"
										rows="3"
										placeholder="What are the solutions/softwares you're using at the moment?"
									></textarea>
								</div> -->

                <!-- <div class="form-group">
									<input
										type="text"
										name="actualSolutionsCost"
										[attr.data-aos]="aosAnimation"
										[attr.data-aos-duration]="aosDuration"
										[attr.data-aos-delay]="
											aosShowDelay.slow
										"
										[(ngModel)]="
											waitList.actualSolutionsCost
										"
										(change)="
											onChange('actualSolutionsCost')
										"
										class="form-control"
										placeholder="How much do you currently pay for software?"
									/>
								</div> -->

                <!-- <div class="form-group">
									<input
										type="text"
										name="position"
										[attr.data-aos]="aosAnimation"
										[attr.data-aos-duration]="aosDuration"
										[attr.data-aos-delay]="
											aosShowDelay.slow
										"
										[(ngModel)]="waitList.position"
										(change)="onChange('position')"
										class="form-control"
										placeholder="What is your position in the organization?"
									/>
								</div> -->

                <!-- <div class="form-group">
									<textarea
										name="decisionMaker"
										class="form-control"
										(change)="onChange('decisionMaker')"
										cols="30"
										[attr.data-aos]="aosAnimation"
										[attr.data-aos-duration]="aosDuration"
										[attr.data-aos-delay]="
											aosShowDelay.slow
										"
										[(ngModel)]="waitList.decisionMaker"
										rows="4"
										placeholder="Who takes the decisions regarding software in your organization?"
									></textarea>
								</div> -->
              </ng-container>

              <app-select-options
                [attr.data-aos]="aosAnimation"
                [attr.data-aos-duration]="aosDuration"
                [attr.data-aos-delay]="aosShowDelay.slow"
                label="Features that interest you"
                [enumOptions]="enumOptions"
                groupBy="group"
                [multiple]="true"
                [(ngModels)]="waitList.interestedFeatures"
              ></app-select-options>

              <div class="form-group">
                <textarea
                  name="otherFeatures"
                  class="form-control"
                  (change)="onChange('otherFeatures')"
                  cols="30"
                  [attr.data-aos]="aosAnimation"
                  [attr.data-aos-duration]="aosDuration"
                  [attr.data-aos-delay]="aosShowDelay.slow"
                  [(ngModel)]="waitList.otherFeatures"
                  rows="3"
                  placeholder="Other features you're interested in"
                ></textarea>
              </div>

              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="haveARefferalPerson"
                  name="haveARefferalPerson"
                  [attr.data-aos]="aosAnimation"
                  [attr.data-aos-duration]="aosDuration"
                  [attr.data-aos-delay]="aosShowDelay.slow"
                  [(ngModel)]="haveARefferalPerson"
                  [checked]="haveARefferalPerson"
                  (change)="onChange('scheduleMeeting')"
                />
                <label
                  class="form-check-label"
                  for="haveARefferalPerson"
                  [attr.data-aos]="aosAnimation"
                  [attr.data-aos-duration]="aosDuration"
                  [attr.data-aos-delay]="aosShowDelay.slow"
                  >Someone told you about us?</label
                >
              </div>
              <div class="form-group" *ngIf="haveARefferalPerson">
                <textarea
                  name="refferalContact"
                  class="form-control"
                  (change)="onChange('refferalContact')"
                  [attr.data-aos]="aosAnimation"
                  [attr.data-aos-duration]="aosDuration"
                  [attr.data-aos-delay]="aosShowDelay.slow"
                  [(ngModel)]="waitList.refferalContact"
                  placeholder="Please enter the name and email or phone number of your referral"
                  cols="30"
                  rows="6"
                ></textarea>
              </div>

              <!-- <div class="form-group">
								<input
									name="refferalContact"
									class="form-control"
									(change)="onChange('notes')"
									[attr.data-aos]="aosAnimation"
									[attr.data-aos-duration]="aosDuration"
									[attr.data-aos-delay]="aosShowDelay.slow"
									[(ngModel)]="waitList.refferalContact"
									placeholder="Who told you about us? Please enter their name and email or phone number"
								/>
							</div> -->
              <!-- <div class="col-lg-12 col-md-12">
								<div class="form-check">
									<input
										type="checkbox"
										class="form-check-input"
										id="create-an-account"
										name="scheduleMeeting"
										[attr.data-aos]="aosAnimation"
										[attr.data-aos-duration]="aosDuration"
										[attr.data-aos-delay]="
											aosShowDelay.slow
										"
										[(ngModel)]="waitList.scheduleMeeting"
										[checked]="waitList.scheduleMeeting"
										(change)="onChange('scheduleMeeting')"
									/>
									<label
										class="form-check-label"
										for="create-an-account"
										[attr.data-aos]="aosAnimation"
										[attr.data-aos-duration]="aosDuration"
										[attr.data-aos-delay]="
											aosShowDelay.slow
										"
										>Do you want to meet with us
										online?</label
									>
								</div>
							</div> -->
              <div class="form-group">
                <textarea
                  name="scheduleMeetingNotes"
                  class="form-control"
                  (change)="onChange('scheduleMeetingNotes')"
                  cols="30"
                  [attr.data-aos]="aosAnimation"
                  [attr.data-aos-duration]="aosDuration"
                  [attr.data-aos-delay]="aosShowDelay.slow"
                  [(ngModel)]="waitList.scheduleMeetingNotes"
                  rows="4"
                  placeholder="Please suggest a date and time. We'll get back to you with a confirmation and a Google Meet link."
                ></textarea>
              </div>
              <div class="form-group">
                <textarea
                  name="notes"
                  class="form-control"
                  (change)="onChange('notes')"
                  cols="30"
                  [attr.data-aos]="aosAnimation"
                  [attr.data-aos-duration]="aosDuration"
                  [attr.data-aos-delay]="aosShowDelay.slow"
                  [(ngModel)]="waitList.notes"
                  rows="6"
                  placeholder="Anything else you want to say?"
                ></textarea>
              </div>
            </ng-container>
            <span>{{ error }}</span>
            <span class="success-message" *ngIf="successMessage"
              >{{ successMessage }}
              <p
                (click)="onChange('completeEntireForm')"
                [class.pointer]="shortForm"
                *ngIf="shortForm"
              >
                (If you want to fill out the entire form now,
                <b> click here</b>.)
              </p>
            </span>

            <button
              *ngIf="!successMessage"
              type="submit"
              (click)="$event.preventDefault(); submit(waitListForm)"
              class="btn btn-primary"
            >
              Submit
            </button>
          </form>
        </div>

        <div *ngIf="!shortForm && !successMessage" class="talk-image">
          <div class="talk-shape">
            <img
              src="assets/img/talk/talk-img1.png"
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay="0.6s"
              alt="image"
            />
          </div>

          <div class="talk-arrow">
            <img
              src="assets/img/talk/talk-img2.png"
              class="wow animate__animated animate__fadeInRight"
              data-wow-delay="0.6s"
              alt="image"
            />
          </div>
        </div>
      </div>
      <div *ngIf="showContactUsDetails" class="col-lg-6 col-md-12">
        <app-contact-us-details></app-contact-us-details>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</section>
