<app-navbar-one></app-navbar-one>

<div class="page-title-area">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="page-title-content">
					<h2>Blog Details</h2>

					<ul>
						<li><a routerLink="/">Home</a></li>
						<li>Blog Details</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<section class="blog-details-area ptb-100">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 col-md-12">
				<div class="blog-details-desc">
					<div class="article-image">
						<img
							src="assets/img/blog-details/img1.jpg"
							alt="image"
						/>
					</div>

					<div class="article-content">
						<div class="entry-meta">
							<ul>
								<li>
									<span>Posted On:</span>
									<a routerLink="/">September 31, 2020</a>
								</li>
								<li>
									<span>Posted By:</span>
									<a routerLink="/">John Anderson</a>
								</li>
							</ul>
						</div>

						<h3>Why Business Absolutely Needs a Virtual Office</h3>

						<p>
							Quuntur magni dolores eos qui ratione voluptatem
							sequi nesciunt. Neque porro quia non numquam eius
							modi tempora incidunt ut labore et dolore magnam
							dolor sit amet, consectetur adipisicing.
						</p>

						<p>
							Lorem ipsum dolor sit amet, consectetur adipisicing
							elit, sed do eiusmod tempor incididunt ut labore et
							dolore magna aliqua. Ut enim ad minim veniam, quis
							nostrud exercitation ullamco laboris nisi ut aliquip
							ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in
							sed quia non numquam eius modi tempora incidunt ut
							labore et dolore magnam aliquam quaerat voluptatem.
						</p>

						<blockquote class="wp-block-quote">
							<p>
								It is a long tablished fact that a reader will
								be distracted by the readable content of a page
								when looking at its layout.
							</p>
							<cite>Tom Cruise</cite>
						</blockquote>

						<p>
							Quuntur magni dolores eos qui ratione voluptatem
							sequi nesciunt. Neque porro quia non numquam eius
							modi tempora incidunt ut labore et dolore magnam
							asperiores aut saepe recusandae fuga dolor sit amet,
							consectetur adipisicing. Lorem ipsum dolor sit amet
							consectetur, adipisicing elit. Maxime vero
							consequuntur eveniet asperiores aut saepe recusandae
							fuga nostrum, nisi eaque ullam nihil accusamus ipsam
							fugiat commodi dolores dolorem harum mollitia!
						</p>

						<ul class="wp-block-gallery columns-3">
							<li class="blocks-gallery-item">
								<figure>
									<img
										src="assets/img/blog-details/img1.jpg"
										alt="image"
									/>
								</figure>
							</li>

							<li class="blocks-gallery-item">
								<figure>
									<img
										src="assets/img/blog-details/img2.jpg"
										alt="image"
									/>
								</figure>
							</li>

							<li class="blocks-gallery-item">
								<figure>
									<img
										src="assets/img/blog-details/img3.jpg"
										alt="image"
									/>
								</figure>
							</li>
						</ul>

						<p>
							Lorem ipsum, dolor sit amet consectetur adipisicing
							elit. Dolorum, similique dignissimos corporis ut
							consequatur quam id labore odio blanditiis? Non
							incidunt suscipit deleniti fuga pariatur sunt beatae
							quasi magni itaque! Lorem ipsum dolor sit amet
							consectetur adipisicing elit. Dolor rerum aliquam
							provident. Fugiat libero aliquam ad sequi sed animi,
							omnis illo praesentium. Earum exercitationem
							laudantium excepturi pariatur provident facere
							corrupti.
						</p>

						<h3>Setting the mood with incense</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipisicing
							elit, sed do eiusmod tempor incididunt ut labore et
							dolore magna aliqua. Ut enim ad minim veniam, quis
							nostrud exercitation ullamco laboris nisi ut aliquip
							ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in sed quia non numquam eius modi
							tempora incidunt ut labore et dolore magnam aliquam
							quaerat voluptatem.
						</p>

						<h3>The Rise Of Smarketing And Why You Need It</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipisicing
							elit, sed do eiusmod tempor incididunt ut labore et
							dolore magna aliqua. Ut enim ad minim veniam, quis
							nostrud.
						</p>
					</div>

					<div class="article-footer">
						<div class="article-tags">
							<span><i class="fas fa-bookmark"></i></span>
							<a routerLink="#">Fashion</a>,
							<a routerLink="#">Games</a>
						</div>

						<div class="article-share">
							<ul class="social">
								<li>
									<a href="#" target="_blank"
										><i class="fab fa-facebook-f"></i
									></a>
								</li>
								<li>
									<a href="#" target="_blank"
										><i class="fab fa-twitter"></i
									></a>
								</li>
								<li>
									<a href="#" target="_blank"
										><i class="fab fa-linkedin-in"></i
									></a>
								</li>
								<li>
									<a href="#" target="_blank"
										><i class="fab fa-instagram"></i
									></a>
								</li>
							</ul>
						</div>
					</div>

					<div class="post-navigation">
						<div class="navigation-links">
							<div class="nav-previous">
								<a routerLink="/blog-details"
									><i class="fa fa-arrow-left"></i> Prev
									Post</a
								>
							</div>

							<div class="nav-next">
								<a routerLink="/blog-details"
									>Next Post <i class="fa fa-arrow-right"></i
								></a>
							</div>
						</div>
					</div>

					<div class="comments-area">
						<h3 class="comments-title">2 Comments:</h3>

						<ol class="comment-list">
							<li class="comment">
								<article class="comment-body">
									<footer class="comment-meta">
										<div class="comment-author vcard">
											<img
												src="assets/img/blog-details/image-1.jpg"
												class="avatar"
												alt="image"
											/>
											<b class="fn">John Jones</b>
											<span class="says">says:</span>
										</div>

										<div class="comment-metadata">
											<a routerLink="/"
												><time
													>April 24, 2019 at 10:59
													am</time
												></a
											>
										</div>
									</footer>

									<div class="comment-content">
										<p>
											Lorem Ipsum has been the industry’s
											standard dummy text ever since the
											1500s, when an unknown printer took
											a galley of type and scrambled it to
											make a type specimen.
										</p>
									</div>

									<div class="reply">
										<a
											routerLink="/"
											class="comment-reply-link"
											>Reply</a
										>
									</div>
								</article>

								<ol class="children">
									<li class="comment">
										<article class="comment-body">
											<footer class="comment-meta">
												<div
													class="comment-author vcard"
												>
													<img
														src="assets/img/blog-details/image-2.jpg"
														class="avatar"
														alt="image"
													/>
													<b class="fn"
														>Steven Smith</b
													>
													<span class="says"
														>says:</span
													>
												</div>

												<div class="comment-metadata">
													<a routerLink="/"
														><time
															>April 24, 2019 at
															10:59 am</time
														></a
													>
												</div>
											</footer>

											<div class="comment-content">
												<p>
													Lorem Ipsum has been the
													industry’s standard dummy
													text ever since the 1500s,
													when an unknown printer took
													a galley of type and
													scrambled it to make a type
													specimen.
												</p>
											</div>

											<div class="reply">
												<a
													routerLink="/"
													class="comment-reply-link"
													>Reply</a
												>
											</div>
										</article>
									</li>

									<ol class="children">
										<li class="comment">
											<article class="comment-body">
												<footer class="comment-meta">
													<div
														class="comment-author vcard"
													>
														<img
															src="assets/img/blog-details/image-3.jpg"
															class="avatar"
															alt="image"
														/>
														<b class="fn"
															>Sarah Taylor</b
														>
														<span class="says"
															>says:</span
														>
													</div>

													<div
														class="comment-metadata"
													>
														<a routerLink="/"
															><time
																>April 24, 2019
																at 10:59
																am</time
															></a
														>
													</div>
												</footer>

												<div class="comment-content">
													<p>
														Lorem Ipsum has been the
														industry’s standard
														dummy text ever since
														the 1500s, when an
														unknown printer took a
														galley of type and
														scrambled it to make a
														type specimen.
													</p>
												</div>

												<div class="reply">
													<a
														routerLink="/"
														class="comment-reply-link"
														>Reply</a
													>
												</div>
											</article>
										</li>
									</ol>
								</ol>
							</li>

							<li class="comment">
								<article class="comment-body">
									<footer class="comment-meta">
										<div class="comment-author vcard">
											<img
												src="assets/img/blog-details/image-4.jpg"
												class="avatar"
												alt="image"
											/>
											<b class="fn">James Anderson</b>
											<span class="says">says:</span>
										</div>

										<div class="comment-metadata">
											<a routerLink="/"
												><time
													>April 24, 2019 at 10:59
													am</time
												></a
											>
										</div>
									</footer>

									<div class="comment-content">
										<p>
											Lorem Ipsum has been the industry’s
											standard dummy text ever since the
											1500s, when an unknown printer took
											a galley of type and scrambled it to
											make a type specimen.
										</p>
									</div>

									<div class="reply">
										<a
											routerLink="/"
											class="comment-reply-link"
											>Reply</a
										>
									</div>
								</article>
							</li>
						</ol>

						<div class="comment-respond">
							<h3 class="comment-reply-title">Leave a Reply</h3>

							<form class="comment-form">
								<p class="comment-notes">
									<span id="email-notes"
										>Your email address will not be
										published.</span
									>
									Required fields are marked
									<span class="required">*</span>
								</p>
								<p class="comment-form-comment">
									<label>Comment</label>
									<textarea
										name="comment"
										id="comment"
										cols="45"
										rows="5"
										maxlength="65525"
										required="required"
									></textarea>
								</p>
								<p class="comment-form-author">
									<label
										>Name
										<span class="required">*</span></label
									>
									<input
										type="text"
										id="author"
										name="author"
										required="required"
									/>
								</p>
								<p class="comment-form-email">
									<label
										>Email
										<span class="required">*</span></label
									>
									<input
										type="email"
										id="email"
										name="email"
										required="required"
									/>
								</p>
								<p class="comment-form-url">
									<label>Website</label>
									<input type="url" id="url" name="url" />
								</p>
								<p class="comment-form-cookies-consent">
									<input
										type="checkbox"
										value="yes"
										name="wp-comment-cookies-consent"
										id="wp-comment-cookies-consent"
									/>
									<label for="wp-comment-cookies-consent"
										>Save my name, email, and website in
										this browser for the next time I
										comment.</label
									>
								</p>
								<p class="form-submit">
									<input
										type="submit"
										name="submit"
										id="submit"
										class="submit"
										value="Post Comment"
									/>
								</p>
							</form>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-12">
				<aside class="widget-area" id="secondary">
					<section class="widget widget_search">
						<form class="search-form">
							<label>
								<span class="screen-reader-text"
									>Search for:</span
								>
								<input
									type="search"
									class="search-field"
									placeholder="Search..."
								/>
							</label>
							<button type="submit">
								<i class="fas fa-search"></i>
							</button>
						</form>
					</section>

					<section class="widget widget_zash_posts_thumb">
						<h3 class="widget-title">Popular Posts</h3>

						<article class="item">
							<a routerLink="/blog-details" class="thumb">
								<span
									class="fullimage cover bg1"
									role="img"
								></span>
							</a>
							<div class="info">
								<time class="2019-06-30">June 10, 2019</time>
								<h4 class="title usmall">
									<a routerLink="/blog-details"
										>Making Peace With The Feast Or Famine
										Of Freelancing</a
									>
								</h4>
							</div>
						</article>

						<article class="item">
							<a routerLink="/blog-details" class="thumb">
								<span
									class="fullimage cover bg2"
									role="img"
								></span>
							</a>
							<div class="info">
								<time class="2019-06-30">June 21, 2019</time>
								<h4 class="title usmall">
									<a routerLink="/blog-details"
										>I Used The Web For A Day On A 50 MB
										Budget</a
									>
								</h4>
							</div>
							<div class="clear"></div>
						</article>

						<article class="item">
							<a routerLink="/blog-details" class="thumb">
								<span
									class="fullimage cover bg3"
									role="img"
								></span>
							</a>
							<div class="info">
								<time class="2019-06-30">June 30, 2019</time>
								<h4 class="title usmall">
									<a routerLink="/blog-details"
										>How To Create A Responsive Popup
										Gallery?</a
									>
								</h4>
							</div>
							<div class="clear"></div>
						</article>
					</section>

					<section class="widget widget_categories">
						<h3 class="widget-title">Categories</h3>

						<ul>
							<li><a routerLink="/">Business</a></li>
							<li><a routerLink="/">Privacy</a></li>
							<li><a routerLink="/">Technology</a></li>
							<li><a routerLink="/">Tips</a></li>
							<li><a routerLink="/">Uncategorized</a></li>
						</ul>
					</section>

					<section class="widget widget_recent_comments">
						<h3 class="widget-title">Recent Comments</h3>

						<ul>
							<li>
								<span class="comment-author-link"
									><a routerLink="/"
										>A WordPress Commenter</a
									></span
								>
								on <a routerLink="/">Hello world!</a>
							</li>
							<li>
								<span class="comment-author-link"
									><a routerLink="/">Exto</a></span
								>
								on <a routerLink="/">Hello world!</a>
							</li>
							<li>
								<span class="comment-author-link"
									><a routerLink="/">Wordpress</a></span
								>
								on <a routerLink="/">Hello world!</a>
							</li>
							<li>
								<span class="comment-author-link"
									><a routerLink="/"
										>A WordPress Commenter</a
									></span
								>
								on <a routerLink="/">Hello world!</a>
							</li>
							<li>
								<span class="comment-author-link"
									><a routerLink="/">Exto</a></span
								>
								on <a routerLink="/">Hello world!</a>
							</li>
						</ul>
					</section>

					<section class="widget widget_recent_entries">
						<h3 class="widget-title">Recent Posts</h3>

						<ul>
							<li>
								<a routerLink="/blog-details"
									>How to Become a Successful Entry Level UX
									Designer</a
								>
							</li>
							<li>
								<a routerLink="/blog-details"
									>How to start your business as an
									entrepreneur</a
								>
							</li>
							<li>
								<a routerLink="/blog-details"
									>How to be a successful entrepreneur</a
								>
							</li>
							<li>
								<a routerLink="/blog-details"
									>10 Building Mobile Apps With Ionic And
									React</a
								>
							</li>
							<li>
								<a routerLink="/blog-details"
									>Protect your workplace from cyber
									attacks</a
								>
							</li>
						</ul>
					</section>

					<section class="widget widget_archive">
						<h3 class="widget-title">Archives</h3>

						<ul>
							<li><a routerLink="/">May 2019</a></li>
							<li><a routerLink="/">April 2019</a></li>
							<li><a routerLink="/">June 2019</a></li>
						</ul>
					</section>

					<section class="widget widget_meta">
						<h3 class="widget-title">Meta</h3>

						<ul>
							<li><a routerLink="/">Log in</a></li>
							<li>
								<a routerLink="/"
									>Entries
									<abbr title="Really Simple Syndication"
										>RSS</abbr
									></a
								>
							</li>
							<li>
								<a routerLink="/"
									>Comments
									<abbr title="Really Simple Syndication"
										>RSS</abbr
									></a
								>
							</li>
							<li><a routerLink="/">WordPress.org</a></li>
						</ul>
					</section>

					<section class="widget widget_tag_cloud">
						<h3 class="widget-title">Tags</h3>

						<div class="tagcloud">
							<a routerLink="/"
								>IT <span class="tag-link-count"> (3)</span></a
							>
							<a routerLink="/"
								>Exto
								<span class="tag-link-count"> (3)</span></a
							>
							<a routerLink="/"
								>Games
								<span class="tag-link-count"> (2)</span></a
							>
							<a routerLink="/"
								>Fashion
								<span class="tag-link-count"> (2)</span></a
							>
							<a routerLink="/"
								>Travel
								<span class="tag-link-count"> (1)</span></a
							>
							<a routerLink="/"
								>Smart
								<span class="tag-link-count"> (1)</span></a
							>
							<a routerLink="/"
								>Marketing
								<span class="tag-link-count"> (1)</span></a
							>
							<a routerLink="/"
								>Tips
								<span class="tag-link-count"> (2)</span></a
							>
						</div>
					</section>
				</aside>
			</div>
		</div>
	</div>
</section>
