import { Component } from '@angular/core';
import { SharedComponent } from '../shared.component';
import { Constants } from 'src/app/shared/config/constants.config';

@Component({
	selector: 'app-contact-us-details',
	templateUrl: './contact-us-details.component.html',
	styleUrls: ['./contact-us-details.component.scss'],
})
export class ContactUsDetailsComponent extends SharedComponent {
	Constants = Constants
}
