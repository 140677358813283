<footer class="footer-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="footer-area-content">
          <img src="./../../../../assets/img/logo/6.png" alt="image" />
          <p>
            Our vision is to lead people to a thriving life of physical, mental
            and spiritual health, through innovative technology and
            comprehensive resources.
          </p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <!-- <div class="single-footer-widget">
					<h3>General</h3>

					<ul class="footer-quick-links">
						<li><a routerLink="/">Speaker requests</a></li>
						<li><a routerLink="/">Images and b-roll</a></li>
						<li><a routerLink="/">Social media directories</a></li>
						<li><a routerLink="/">Permissions</a></li>
						<li><a routerLink="/">Press inquiries</a></li>
					</ul>
				</div> -->
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Useful links</h3>

          <ul class="footer-quick-links">
            <li><a routerLink="/about">About us </a></li>
            <li><a routerLink="/pricing">Pricing</a></li>
            <li>
              <a routerLink="/affiliate">Affiliate program</a>
            </li>
            <li><a routerLink="/scholarship">Scholarship</a></li>
            <!-- <li><a routerLink="/">Latest News</a></li> -->
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Contact</h3>

          <ul class="footer-quick-links address-link">
            <li>
              <a class="link" href="mailto:{{ Constants.email }}"
                ><i class="fa fa-envelope"></i> {{ Constants.email }}</a
              >
            </li>
            <li>
              <a class="link" href="tel:{{ Constants.phone }}"
                ><i class="fa fa-phone"></i> {{ Constants.phone }}</a
              >
            </li>
            <li>
              <span class="link" href=""
                ><i class="fa fa-map-marker"></i> {{ Constants.address }}</span
              >
            </li>
          </ul>

          <!-- <ul class="social-list">
                        <li><a href="#" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul> -->
        </div>
      </div>
    </div>

    <div class="copyright-area">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <p>© TeamM 2024</p>
        </div>

        <div class="col-lg-6 col-md-6">
          <ul>
            <li>
              <a routerLink="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a routerLink="/terms-and-conditions">Terms & Conditions</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="go-top">
  <i class="fas fa-chevron-up"></i>
  <i class="fas fa-chevron-up"></i>
</div>

<div class="sidebar-modal">
  <div
    class="modal right fade"
    id="myModal2"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel2"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="fa fa-times"></i>
            </span>
          </button>

          <h2 class="modal-title" id="myModalLabel2">
            <a routerLink="/">
              <img src="assets/img/logo/logo.png" alt="Logo" />
            </a>
          </h2>
        </div>

        <div class="modal-body">
          <div class="sidebar-modal-widget">
            <h3 class="title">Welcome To TeamM</h3>
            <img src="assets/img/about.jpg" alt="" />
            <p>
              <!-- Lorem ipsum dolor sit amet consectetur adipisicing
							elit. Nemo dolore saepe exercitationem alias unde,
							blanditiis non error -->
            </p>
          </div>

          <div class="sidebar-modal-widget">
            <h3 class="title">Contact Info</h3>

            <ul class="contact-info">
              <li>
                <i class="fa fa-map-marker"></i>
                Address
                <span>{{ Constants.address }}</span>
              </li>

              <li>
                <i class="fa fa-phone"></i>
                Email
                <span>{{ Constants.email }}</span>
              </li>

              <li>
                <i class="fa fa-envelope"></i>
                Phone
                <span>{{ Constants.phone }}</span>
              </li>
            </ul>
          </div>

          <div class="sidebar-modal-widget">
            <h3 class="title">Connect With Us</h3>

            <ul class="social-list">
              <li>
                <a href="#" target="_blank"
                  ><i class="fab fa-facebook-f"></i
                ></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
