import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
	private tokenSubject: Subject<string> = new ReplaySubject(1);

	get token$(): Observable<string> {
		return this.tokenSubject.asObservable();
	}

	constructor() {
		this.tokenSubject.next(null);
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		console.log('HERE');

		return this.token$.pipe(
			take(1),
			mergeMap((token) => {
				let headers = req.headers;
				if (token) {
					headers = req.headers.append(
						'Authorization',
						`Bearer ${token}`
					);
				}
				console.log({ headers });

				if (
					!req.url.startsWith('/assets') &&
					!req.url.startsWith('https://') &&
					!req.url.startsWith('http://')
				) {
					const url = `${environment.apiBaseUrl}/${req.url}`;
					const apiReq = req.clone({
						url,
						headers,
					});
					return next.handle(apiReq);
				}
				return next.handle(req);
			}),
			catchError((err) => {
				const { status } = err;
				if (status === 401) {
					console.log('401 error');
				}
				throw err;
			})
		);
	}
}
