import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	forwardRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectGroup } from '../../models/shared.model';
import { Constants } from '../../config/constants.config';
import { AbstractInputComponent } from './abstract-input.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-select-options',
	templateUrl: './select-options.component.html',
	styleUrls: ['./select-options.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectOptionsComponent),
			multi: true,
		},
	],
})
export class SelectOptionsComponent
	extends AbstractInputComponent
	implements OnInit, OnChanges
{
	@Input() enum: any;
	@Input() enumOptions: any[];
	@Input() selectType?: 'select' | 'radio' | 'checkbox' = 'select';
	@Input() radioClass?: 'row' | 'column' = 'row';
	@Input() fieldFilter: string;
	@Input() label: string;
	@Input() ngModel: any;
	@Input() multiple: boolean;
	@Input() groupBy: string;
	matSelectGroup: MatSelectGroup[] = [];

	@Input() ngModels: string[] = []; // used for filter multiple (for now)
	@Output() ngModelChange = new EventEmitter<any>();

	@Output() ngModelsChange = new EventEmitter<string[]>();
	@Output() humanizeValue = new EventEmitter<string>();

	Constants = Constants;

	constructor(private router: Router, private route: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		if (this.enum) {
			this.enumOptions = Object.values(this.enum).map((value) => ({
				value: value,
				label: value,
			}));
		}

		if (this.fieldFilter) {
			this.route.queryParamMap
				.pipe(untilDestroyed(this))
				.subscribe((params) => {
					const ngModel = params.get(this.fieldFilter);

					if (ngModel) {
						if (this.multiple) {
							this.ngModels = ngModel.split(',');
							this.ngModelsChange.emit(this.ngModels);
						} else {
							this.ngModel = ngModel;
						}
						this.ngModelChange.emit(ngModel);
						this.humanise();
					} else {
						this.ngModel = null;
						this.ngModelChange.emit(null);
						this.ngModels = [];
						this.ngModelsChange.emit([]);
						this.humanise();
					}
				});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.

		if (changes['enumOptions']?.currentValue) {
			if (this.groupBy) {
				this.matSelectGroup = [];
				for (const item of this.enumOptions) {
					const group = this.matSelectGroup.find(
						(group) => group.name === item[this.groupBy]
					);
					if (group) {
						group.options.push(item);
					} else {
						this.matSelectGroup.push({
							name: item[this.groupBy],
							options: [item],
						});
					}
				}
			}
		}
	}

	checkboxChanged(event, tagId: string) {
		if (!this.ngModel) this.ngModel = [];
		if (event.checked) {
			this.ngModel.push(tagId);
		} else {
			this.ngModel.splice(this.ngModel.indexOf(tagId), 1);
		}
		this.ngModelChange.emit(this.ngModel);
	}

	async valueHasChanged(items) {
		this.ngModelChange.emit(items);
		this.ngModelsChange.emit(items);
		if (!this.fieldFilter) return;
		if (!this.multiple) items = items ? [items] : [];
		const queryParams: any = {};
		queryParams[this.fieldFilter] = items.join(',') || null;
		this.humanise();

		await this.router.navigate([], {
			relativeTo: this.route,
			queryParams,
			queryParamsHandling: 'merge',
			replaceUrl: true,
		});
	}

	humanise() {
		if (this.multiple) {
			this.humanizeValue.emit(
				this.enumOptions
					.filter((item) => this.ngModels.includes(item.value))
					.map((item) => item.label)
					.join(', ')
			);
		} else {
			this.humanizeValue.emit(
				this.enumOptions.find((item) => item.value === this.ngModel)
					?.label
			);
		}
	}
}
