<app-navbar-one></app-navbar-one>

<div class="page-title-area">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="page-title-content">
					<h2>Team</h2>

					<ul>
						<li><a routerLink="/">Home</a></li>
						<li>Team</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<section class="team-section inner-team ptb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team1.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">White Amlington</a></h3>
						<span>ManagerLogistic</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team2.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">Agaton Ronald</a></h3>
						<span>Scientist,Ecology</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team3.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">Alex Wood</a></h3>
						<span>Scientist,Economic</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team4.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">Mexwell White</a></h3>
						<span>Owner & CEOEconomic</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team5.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">Alastair Cook</a></h3>
						<span>Marketing Manager</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team6.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">Meg Lanning</a></h3>
						<span>Lead Architecure</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team7.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">James Anderson</a></h3>
						<span>Marketing Support</span>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-team-member">
					<div class="member-image">
						<img src="assets/img/team/team8.jpg" alt="image" />
					</div>

					<div class="member-content">
						<h3><a routerLink="/">Sarah Taylor</a></h3>
						<span>Lead Architecure</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
