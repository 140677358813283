import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/shared/config/config';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';

@Component({
	selector: 'app-scholarship-page',
	templateUrl: './scholarship-page.component.html',
	styleUrls: ['./scholarship-page.component.scss'],
})
export class ScholarshipPageComponent implements OnInit {
	constructor(private mixpanelService: MixpanelService) {}

	ngOnInit(): void {
		this.mixpanelService.track('View Scolarship Page', {});
	}

	aosAnimation = Config.aos.animation;
	aosDuration = Config.aos.duration; //ms
	aosShowDelay = Config.aos.showDelay;

	submit(form: any) {
		if (form.status === 'INVALID') {
			return;
		}
		this.saveOrUpdate();
	}
	onChange(field: 'name' | 'email' | string) {
		this.mixpanelService.track('Scholarship', {});
		this.saveOrUpdate();
	}

	async saveOrUpdate() {
		try {
			// if (this.scholarship._id) {
			//   this.scholarshipService.updateOne(this.scholarship._id, this.scholarship)
			// } else {
			//   this.scholarship._id = (await this.scholarshipService.addOne(this.scholarship))._id
			// }
		} catch (error) {
			this.mixpanelService.track('Refferal Form Error', error);
			console.error(error);
		}
	}
}
