<mat-form-field
	*ngIf="
		selectType === 'select' &&
		(Constants.style.select === 'outline' ||
			Constants.style.select === 'fill')
	"
	[appearance]="matAppearance || Constants.style.select"
	class="mat-form-group"
	[class.margin]="includeMargin"
	[class.hide-hint]="!matHint"
>
	<mat-label *ngIf="label">{{ label }}</mat-label>
	<mat-select
		[(ngModel)]="multiple ? ngModels : ngModel"
		(ngModelChange)="valueHasChanged($event)"
		[name]="name"
		[multiple]="multiple ?? false"
		[required]="required"
		[disabled]="disabled"
		panelClass="mat-select-panel-custom"
	>
		<ng-container *ngIf="matSelectGroup?.length === 0">
			<mat-option *ngIf="clearable && !required" [value]="null"
				>--</mat-option
			>
			<mat-option
				*ngFor="let option of enumOptions"
				[value]="option.value"
				>{{ option.label }}</mat-option
			>
		</ng-container>

		<ng-container *ngIf="matSelectGroup?.length > 0">
			<mat-optgroup
				*ngFor="let group of matSelectGroup"
				[label]="group.name"
				[disabled]="group.disabled"
			>
				<mat-option
					*ngFor="let item of group.options"
					[value]="item.value"
					[disabled]="item.disabled"
				>
					<ng-container *ngIf="!roomsStatus">
						{{ item.label }}</ng-container
					>
				</mat-option>
			</mat-optgroup>
		</ng-container>
	</mat-select>
	<mat-hint>{{ matHint }}</mat-hint>
</mat-form-field>

<!-- <div *ngIf="selectType === 'select' && Constants.style.select === 'default'">
	<div class="form-group">
		<label *ngIf="label"
			>{{ label
			}}<span class="form-required">{{
				required ? '*' : ''
			}}</span></label
		>
		<ng-select
			[placeholder]="placeholder"
			[(ngModel)]="multiple ? ngModels : ngModel"
			(ngModelChange)="
				valueHasChanged($event); ngModelChange.emit($event)
			"
			[items]="enumOptions"
			[name]="name"
			[required]="required"
			[readonly]="readonly"
			[clearable]="clearable"
			[disabled]="disabled"
			groupBy="group"
			[multiple]="multiple"
			[closeOnSelect]="closeOnSelect ?? true"
			#select
		>
		</ng-select>
		<i
			*ngIf="info"
			[matTooltipClass]="'tooltipClass'"
			[matTooltip]="info"
			class="icon suffix"
			>info</i
		>
	</div>
</div> -->
<!-- 
<div class="mat-form-group" *ngIf="selectType === 'radio'">
	<mat-label class="label" *ngIf="label"
		>{{ label
		}}<span class="form-required">{{
			required ? '*' : ''
		}}</span></mat-label
	>
	<mat-radio-group
		[class]="radioClass === 'column' ? 'd-flex flex-column' : ''"
		[ngModel]="ngModel"
		(ngModelChange)="valueHasChanged($event); ngModelChange.emit($event)"
	>
		<mat-radio-button
			color="primary"
			*ngFor="let option of enumOptions; let i = index"
			class="option"
			[value]="option.value"
			[disabled]="disabled"
			>{{ option.label }}</mat-radio-button
		>
	</mat-radio-group>
</div> -->

<!-- <div class="form-group" *ngIf="selectType === 'checkbox'">
	<label *ngIf="!fieldFilter && label"
		>{{ label }}<span>{{ required ? '*' : '' }}</span></label
	>
	<div class="label" *ngFor="let option of enumOptions">
		<mat-checkbox
			[checked]="ngModel?.includes(option.value)"
			(change)="
				checkboxChanged($event, option.value); valueHasChanged($event)
			"
			[disabled]="disabled"
		>
			{{ option.label }}
		</mat-checkbox>
	</div>
</div> -->
