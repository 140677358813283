import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export abstract class CrudService<T> {
	public dataChangedSubject: Subject<boolean> = new Subject<boolean>();
	// locale: Locale;
	// listTableData: PagedResponse<T>;
	// listTableQueryParams: TableQueryParams;

	getAllData: T[];

	useCacheData: boolean = false;
	getOneByGuestIdData: T;

	public dataChanged$(): Observable<boolean> {
		return this.dataChangedSubject.asObservable();
	}

	protected constructor(
		protected http: HttpClient,
		protected endpoint: string
	) {
		// this.locale = sessionService.getCurrentLanguage();
	}

	async getOne(id: string): Promise<T> {
		const resource = await this.http
			.get<T>(`${this.endpoint}/${id}`)
			.toPromise();

		return resource;
	}

	async addOne(data: T): Promise<T> {
		const resp = await this.http
			.post<T>(`${this.endpoint}/`, data)
			.toPromise();

		this.dataChangedSubject.next(true);
		return resp;
	}

	async updateOne(
		id: string,
		data: Partial<T>,
		dataChange = true
	): Promise<T> {
		const updatedObject = await this.http
			.put<T>(`${this.endpoint}/${id}`, data, {
				// update a different locale than the current one you're using
			})
			.toPromise();

		if (dataChange) {
			this.dataChangedSubject.next(true);
		}
		return updatedObject;
	}

	async deleteOne(id: string) {
		const result = await this.http
			.delete(`${this.endpoint}/${id}`)
			.toPromise();

		this.dataChangedSubject.next(true);
		return result;
	}
}
