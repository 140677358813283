import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { WaitlistRecord } from '../models/wait-list.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class WaitListService extends CrudService<WaitlistRecord> {
	constructor(http: HttpClient) {
		super(http, 'waitlist-records');
	}
}
