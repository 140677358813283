<app-navbar-one></app-navbar-one>

<div class="page-title-area">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="page-title-content">
					<h2>Services Details</h2>

					<ul>
						<li><a routerLink="/">Home</a></li>
						<li>Services Details</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<section class="services-details-area ptb-100">
	<div class="container">
		<div class="services-details-overview">
			<div class="row align-items-center">
				<div class="col-lg-6 col-md-12">
					<div class="services-details-desc">
						<h3>Digital Marketing</h3>
						<p>
							There are many variations of passages of Lorem Ipsum
							available, but the majority have suffered alteration
							in some form, by injected humour, or randomised
							words which don't look even slightly believable.
						</p>

						<div class="features-text">
							<h4>
								<i class="flaticon-check-mark"></i> Core
								Development
							</h4>
							<p>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
								the industry's.
							</p>
						</div>

						<div class="features-text">
							<h4>
								<i class="flaticon-check-mark"></i> Define Your
								Choices
							</h4>
							<p>
								Lorem Ipsum is simply dummy text of the printing
								and typesetting industry. Lorem Ipsum has been
								the industry's.
							</p>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-md-12">
					<div class="services-details-image">
						<img
							src="assets/img/services-details/services-details1.jpg"
							alt="image"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="services-details-overview">
			<div class="row align-items-center">
				<div class="col-lg-6 col-md-12">
					<div class="services-details-image">
						<img
							src="assets/img/services-details/services-details2.jpg"
							alt="image"
						/>
					</div>
				</div>

				<div class="col-lg-6 col-md-12">
					<div class="services-details-desc">
						<h3>Social Media Marketing</h3>
						<p>
							Lorem Ipsum is simply dummy text of the printing and
							typesetting industry. Lorem Ipsum has been the
							industry's.
						</p>

						<div class="services-details-accordion">
							<ul class="accordion">
								<li class="accordion-item">
									<a
										class="accordion-title active"
										href="javascript:void(0)"
										><i class="fas fa-plus"></i> Which
										material types can you work with?</a
									>
									<p class="accordion-content show">
										Lorem ipsum dolor sit amet, consectetur
										adipiscing elit, sed do eiusmod tempor
										incididunt ut labore et dolore magna
										aliqua.
									</p>
								</li>

								<li class="accordion-item">
									<a
										class="accordion-title"
										href="javascript:void(0)"
										><i class="fas fa-plus"></i> Is Smart
										Lock required for instant apps?</a
									>
									<p class="accordion-content">
										Lorem ipsum dolor sit amet, consectetur
										adipiscing elit, sed do eiusmod tempor
										incididunt ut labore et dolore magna
										aliqua. Quis ipsum suspendisse ultrices
										gravida.
									</p>
								</li>

								<li class="accordion-item">
									<a
										class="accordion-title"
										href="javascript:void(0)"
										><i class="fas fa-plus"></i> Why Choose
										Our Services In Your Business?</a
									>
									<p class="accordion-content">
										Lorem ipsum dolor sit amet, consectetur
										adipiscing elit, sed do eiusmod tempor
										incididunt ut labore et dolore magna
										aliqua. Quis ipsum suspendisse ultrices
										gravida.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
