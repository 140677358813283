<app-navbar-one></app-navbar-one>

<!-- <div class="page-title-area">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="page-title-content">
					<h2>Pricing</h2>

					<ul>
						<li><a routerLink="/">Home</a></li>
						<li>Pricing</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div> -->

<!-- PRICING TABLE  -->
<section>
  <div class="pricingTable">
    <h2
      class="pricingTable-title page-header"
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay="300"
    >
      Choose the plan that's right for you,
      <!-- Be part of this great movement, -->
    </h2>
    <h3
      class="pricingTable-subtitle"
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay="500"
    >
      <!-- by helping us -->
      come on board, and be part of the movement!
    </h3>
    <!-- <stripe-pricing-table pricing-table-id="prctbl_1P6bbaDksF9fQeL08SDUhMbQ"
				publishable-key="pk_test_51P6avwDksF9fQeL0aghBIAlIqjm3jRHVDy9QoM7KUbQWC6V8DRoW1uoOQDzXqxPzDRVN1rNoJ7tzPdDWYBaWMwVy00ICzJ0huN">
			</stripe-pricing-table> -->
    <app-drd></app-drd>

    <div
      class="pricingTable-switcher"
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay="600"
    >
      <div
        class="pricingTable-switcher_label"
        [class.selected]="viewMode === 'monthly'"
        (click)="viewMode = 'monthly'"
      >
        Monthly
      </div>
      <div
        class="yearly"
        [class.selected]="viewMode === 'yearly'"
        (click)="viewMode = 'yearly'"
      >
        Yearly
      </div>
    </div>

    <ul class="pricingTable-firstTable">
      <li
        *ngFor="let plan of plans; let i = index"
        class="pricingTable-firstTable_table"
        data-aos="fade-up"
        data-aos-duration="800"
        [attr.data-aos-delay]="700 + i * 150"
      >
        <h1 class="pricingTable-firstTable_table__header">
          {{ plan.title }}
        </h1>
        <div class="pricingTable-firstTable_table__pricing">
          <span>€</span
          ><span>{{
            viewMode === "monthly"
              ? plan.price
              : (plan.price * ((100 - yearlyDiscount) / 100)).toFixed(0)
          }}</span
          ><span>/ month</span>
          <div *ngIf="plan.price && viewMode === 'yearly'" class="discount">
            You save €{{
              (
                plan.price * 12 -
                plan.price * 12 * ((100 - yearlyDiscount) / 100)
              ).toFixed(0)
            }}
            / year
          </div>
        </div>
        <ul class="pricingTable-firstTable_table__options">
          <ng-container *ngFor="let feature of plan.features">
            <span class="line" *ngIf="!feature"></span>

            <li *ngIf="feature !== ''">
              {{ feature }}
            </li>
          </ng-container>
        </ul>
        <button class="pricingTable-firstTable_table__getstart">
          Contact Us
        </button>
      </li>
      <!-- <li class="pricingTable-firstTable_table">
				<h1 class="pricingTable-firstTable_table__header">
					Gold Packege
				</h1>
				<p class="pricingTable-firstTable_table__pricing">
					<span>$</span><span>19</span><span>Month</span>
				</p>
				<ul class="pricingTable-firstTable_table__options">
					<li>Unlimited Listing</li>
					<li>Edit Your Listing</li>
					<li>Approve Reviews</li>
					<li>Take Booking Online</li>
					<li>24/7 Support Service</li>
				</ul>
				<button class="pricingTable-firstTable_table__getstart">
					Get Started Now
				</button>
			</li>
			<li class="pricingTable-firstTable_table">
				<h1 class="pricingTable-firstTable_table__header">
					Platinum Packege
				</h1>
				<p class="pricingTable-firstTable_table__pricing">
					<span>$</span><span>49</span><span>Month</span>
				</p>
				<ul class="pricingTable-firstTable_table__options">
					<li>Unlimited Listing</li>
					<li>Edit Your Listing</li>
					<li>Approve Reviews</li>
				</ul>
				<button class="pricingTable-firstTable_table__getstart">
					Get Started Now
				</button>
			</li> -->
    </ul>
  </div>
  <p></p>
</section>

<!-- 
<section class="pricing-section ptb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="pricing-area-content">
					<span>The support</span>
					<h3>Management is easy when getting support properly</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit, sed do eiusmod tempor incididunt ut labore et
						dolore magna aliqua. Ut enim ad minim veniam.
					</p>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit, sed do eiusmod tempor incididunt ut labore et
						dolore.
					</p>
				</div>

				<div class="pricing-btn-wrap">
					<div class="price-btn">
						<a routerLink="/" class="price-btn-one"
							>Pricing Plan <i class="fa fa-arrow-right"></i
						></a>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-pricing-box">
					<div class="pricing-header">
						<h3>Bussines Account</h3>
					</div>

					<div class="price">$56/ <span>Monthly</span></div>

					<ul class="pricing-features">
						<li>
							<i class="fa fa-arrow-right"></i> Drug & Drop
							builder
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 1009s account
							ready
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Unlimited Email
						</li>
						<li><i class="fa fa-arrow-right"></i> Blog tools</li>
						<li>
							<i class="fa fa-arrow-right"></i> Ecommerce store
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 40+ Webmaster
							tools
						</li>
					</ul>

					<div class="buy-btn">
						<a routerLink="/" class="buy-btn-one"
							>Create Free account</a
						>
					</div>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-pricing-box table-price">
					<div class="pricing-header">
						<h3>Basic Account</h3>
					</div>

					<div class="price">$70/ <span>Monthly</span></div>

					<ul class="pricing-features">
						<li>
							<i class="fa fa-arrow-right"></i> Drug & Drop
							builder
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 1009s account
							ready
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Unlimited Email
						</li>
						<li><i class="fa fa-arrow-right"></i> Blog tools</li>
						<li>
							<i class="fa fa-arrow-right"></i> Ecommerce store
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 40+ Webmaster
							tools
						</li>
					</ul>

					<div class="buy-btn">
						<a routerLink="/" class="buy-btn-one"
							>Start Free trial</a
						>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="single-pricing-box table-price">
					<div class="pricing-header">
						<h3>Basic Account</h3>
					</div>

					<div class="price">$70/ <span>Monthly</span></div>

					<ul class="pricing-features">
						<li>
							<i class="fa fa-arrow-right"></i> Drug & Drop
							builder
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 1009s account
							ready
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> Unlimited Email
						</li>
						<li><i class="fa fa-arrow-right"></i> Blog tools</li>
						<li>
							<i class="fa fa-arrow-right"></i> Ecommerce store
						</li>
						<li>
							<i class="fa fa-arrow-right"></i> 40+ Webmaster
							tools
						</li>
					</ul>

					<div class="buy-btn">
						<a routerLink="/" class="buy-btn-one"
							>Start Free trial</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->

<!-- <section class="saying-section ptb-100">
	<div class="container">
		<div class="saying-title">
			<h3>What is saying clients about us</h3>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
				eiusmod tempor incididunt ut labore et dolet, consectetur
				adipiscing seore suspendisse ultrices gravir.
			</p>
		</div>

		<div class="saying-slides owl-carousel owl-theme">
			<div class="saying-single-item">
				<div class="saying-content-text">
					<h3>It’s Amazing Agency i made ever</h3>
					<p>
						Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod
						tempor incididunt ut labore et Lorem ipsum dolor sit
						amet, consectetur adipisicing elit, sed do eiusmod
						tempor incididunt ut labore et dolore magna aliqua. Ut
						enim ad minim veniam, quis nostrud.
					</p>
				</div>
				<div class="saying-info">
					<div class="say-image">
						<img
							src="assets/img/say-image/say-img1.jpg"
							alt="image"
						/>
					</div>
					<h4>Ketax Andon</h4>
					<span>Bussiness Solution</span>
				</div>
			</div>

			<div class="saying-single-item">
				<div class="saying-content-text">
					<h3>It’s Amazing Agency i made ever</h3>
					<p>
						Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod
						tempor incididunt ut labore et Lorem ipsum dolor sit
						amet, consectetur adipisicing elit, sed do eiusmod
						tempor incididunt ut labore et dolore magna aliqua. Ut
						enim ad minim veniam, quis nostrud.
					</p>
				</div>
				<div class="saying-info">
					<div class="say-image">
						<img
							src="assets/img/say-image/say-img2.jpg"
							alt="image"
						/>
					</div>
					<h4>Kolil Ward</h4>
					<span>CEO Manager</span>
				</div>
			</div>

			<div class="saying-single-item">
				<div class="saying-content-text">
					<h3>It’s Amazing Agency i made ever</h3>
					<p>
						Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod
						tempor incididunt ut labore et Lorem ipsum dolor sit
						amet, consectetur adipisicing elit, sed do eiusmod
						tempor incididunt ut labore et dolore magna aliqua. Ut
						enim ad minim veniam, quis nostrud.
					</p>
				</div>
				<div class="saying-info">
					<div class="say-image">
						<img
							src="assets/img/say-image/say-img3.jpg"
							alt="image"
						/>
					</div>
					<h4>Jandhu Dew</h4>
					<span>Marketing Manager</span>
				</div>
			</div>

			<div class="saying-single-item">
				<div class="saying-content-text">
					<h3>It’s Amazing Agency i made ever</h3>
					<p>
						Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod
						tempor incididunt ut labore et Lorem ipsum dolor sit
						amet, consectetur adipisicing elit, sed do eiusmod
						tempor incididunt ut labore et dolore magna aliqua. Ut
						enim ad minim veniam, quis nostrud.
					</p>
				</div>
				<div class="saying-info">
					<div class="say-image">
						<img
							src="assets/img/say-image/say-img4.jpg"
							alt="image"
						/>
					</div>
					<h4>Andon Smait</h4>
					<span>Bussiness Solution</span>
				</div>
			</div>
		</div>
	</div>
</section> -->

<!-- <section class="request-section ptb-100">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12">
				<div class="request-area">
					<h3>Submit a Request</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit
						sed do eiusmod tempor incididunt.
					</p>

					<div class="request-form">
						<form>
							<div class="form-group">
								<input
									type="text"
									class="form-control"
									placeholder="Whats Your Name"
								/>
							</div>

							<div class="form-group">
								<input
									type="text"
									class="form-control"
									placeholder="Your Phone Number"
								/>
							</div>

							<div class="form-group">
								<input
									type="text"
									class="form-control"
									placeholder="Time For call"
								/>
							</div>

							<div class="form-group">
								<input
									type="text"
									class="form-control"
									placeholder="Your Cosultency Expert"
								/>
							</div>

							<button type="submit" class="btn btn-primary">
								Call For Consultent
							</button>
						</form>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-md-12">
				<div class="request">
					<img src="assets/img/request.jpg" alt="image" />
				</div>
			</div>
		</div>
	</div>
</section> -->

<app-wait-list-form
  data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="700"
  [shortForm]="true"
  [showNavBar]="false"
  [showContactUsDetails]="false"
>
  <div class="col-lg-6 col-md-12">
    <app-contact-us-details></app-contact-us-details>
  </div>
</app-wait-list-form>
